/* .featuresContainer {
    margin: 0px auto;
    width: 100%;
    padding: 0px 60px 20px 60px;
  
   
  } */
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .autoGrid {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .items {
    width: 100%;
  }
  
  
  .listItem {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left !important;
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
    border-radius: 8px;
  }
  
  .listItem h2 {
    text-align: center;
    padding: 15px 25px 0px 25px;
    text-align: center;
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }
  
  
  .listItem p {
    text-align: center;
    padding: 0px 25px 10px 25px;
    text-align: center;
    cursor: pointer;
  }
  
  .designation {
    font-size: 14px;
    line-height: 17px;
    color: rgb(73, 73, 73);
  }

  /* .listItem:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  } */
  

  
  .imgThumbnail {
    margin: 0px auto;
    border-radius: 100%;
    object-fit: cover;
    width: 30vh;
    height: 30vh;
  }
  .imgThumbnail:hover {
    filter: grayscale(100%);
    cursor: pointer;
  }


.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
  

  @media all and (max-width: 768px) {
  
    .items {
      width: 100%;
    }

    .imgThumbnail {
      width: 40vh;
      height: 40vh;
    }
  
   
  }
  