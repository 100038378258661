.layout {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.header {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.header input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.header input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.searchBar {
    display: flex;
}

.searchBar button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.searchBar button:hover {
    background-color: #33583b;
}

.cardContainer {
    width: 65%;
    margin: 0px auto;
}


.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0px;
    
}


.pageNum {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.bg {
    background-color: #3e6947;
}

.pageNum:focus {
    background-color: #3e6947;
    color: white;
}



.button {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.button:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}

@media all and (max-width: 768px) {
    .featuresContainer {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .paragraph {
      width: 100%;
    }

    .header {
      width: 95%;

  }

  .cardContainer {
    width: 98%;
}

.searchBar button {
    width: 30%;
}

  
  }
