.featuresContainer {
    margin: 0px auto;
    /* background-image: url("https://res.cloudinary.com/wabis/image/upload/v1636710062/special/Capacity-Building-cleaned_f09ajt.jpg");
    background-color: #cccccc; */
    margin-bottom: 50px;
   
  }
  
  .autoGrid {
    --auto-grid-min-size: 30rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 0rem;
  }

  /* For Projectors or Higher Resolution Screens (Full HD) */
  /* @media screen and (min-width: 1280px) {
    .autoGrid {
      --auto-grid-min-size: 70rem;
    }
    } */
  
  .items {
    width: 100%;
    background: #eafada;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  
  
  .paragraph {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .listItem {
    padding: 0rem 0rem;
    padding-bottom: 0rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    background-color: #eafada;
  }
  
  .listItem h2 {
    text-align: left;
    padding: 10px 25px;
    font-size: 45px;
    line-height: 50px;
    font-family: 'Roboto', sans-serif;
  }
  
  .listItem h2:hover {
    color: #50835a;
  }
  
  .listItem ul li p {
    text-align: left !important;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 22px;
  }
  
  .listItem:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .imgThumbnail {
    width: 100%;
    /* border-radius: 8px 8px 0px 0px; */
    object-fit: cover;
    /* height: 100vh; */
  }
  .imgThumbnail:hover {
    filter: brightness(95%);
    cursor: pointer;
  }
  


  .button {
    float: left;
    padding: 12px 16px;
    border-radius: 4px;
    border: solid none;
    cursor: pointer;
    background-color: #3e6947;
    color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px #00000012;
  margin: 30px;

}

.button:hover {
    background-color: white;
    color: black;
    border: solid 1px #3e6947;
}
  
  
  @media all and (max-width: 768px) {
    .featuresContainer {
      width: 100%;
      display: block;
    }

    .autoGrid {
      --auto-grid-min-size: 15rem;

    }
  
    .items {
      width: 100%;
    }
  
    .listItem h2 {
      text-align: left;
      padding: 10px 25px;
      font-size: 25px;
      line-height: 30px;
    }

    .paragraph {
      width: 100%;
     
    }
  
    .imgThumbnail {
      height: 50vh;
    }
  }
  