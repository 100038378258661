
.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 50px;
  padding: 12px 30px 12px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07);
  
}

.editButton:hover {
  background-color: #1abc9c;
  border: solid 1px white;
  
}
  