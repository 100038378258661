
.green {
    width: 35px;
    height: 35px;
    background: #3E6947;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  
  
  .red {
    width: 35px;
    height: 35px;
    background: rgb(235, 17, 17);
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .yellow {
    width: 35px;
    height: 35px;
    background: #F9E814;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .yellowishGreen{
    width: 35px;
    height: 35px;
    background: #CCE226;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .gray {
    width: 35px;
    height: 35px;
    background: #D1D1C6;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }