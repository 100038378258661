.layout {
    padding-top: 120px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.header {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.header input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}


.newBtn {
  padding: 15px 20px;
  border: solid 2px #3e6947;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;

}

.newBtn:hover {
  background-color: #3e6947;
  color: white;
}

.header input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.searchBar {
    display: flex;
}

.searchBar button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.searchBar button:hover {
    background-color: #33583b;
}

.cardContainer {
    width: 90%;
    margin: 0px auto;
}


.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0px;
    
}


.pageNum {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.bg {
    background-color: #3e6947;
}

.pageNum:focus {
    background-color: #3e6947;
    color: white;
}

.editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 50px;
    padding: 15px 30px 15px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    margin: 10px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
  }

  .btnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
  }


@media all and (max-width: 768px) {
    .featuresContainer {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .paragraph {
      width: 100%;
    }

    .header {
      width: 95%;

  }

  .cardContainer {
    width: 98%;
}

.searchBar button {
    width: 30%;
}
  
  }






  /* .featuresContainer {
    margin: 0px auto;
    width: 100%;
    padding: 0px 60px 20px 60px;
  
   
  } */
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
    .autoGrid {
        --auto-grid-min-size: 18rem;
      
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0.5rem;
      }
      
      .items {
        width: 100%;
        padding-bottom: 30px;
      }
      
      
      .listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0rem 1rem;
        text-align: left;
        list-style-type: none;
        text-align: left !important;
        border-bottom: 1px solid rgb(184, 184, 184);
        border-radius: 4px;
        background-color: white;
      }
    
      .text p {
        color: gray;
        font-size: gray;
        text-align: left;
        font-size: 13px;
        line-height: 15px;
      }
    
      .text h2 {
        text-align: left;
      }
      
      .listItem h2 {
        text-align: left;
        padding: 15px 25px 0px 25px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        text-align: left;
        font-size: 14px;
        line-height: 23px;
        cursor: pointer;
      }
      
      
      .listItem p {
        text-align: left;
        padding: 0px 25px 10px 25px;
        font-family: 'Roboto', sans-serif;
        text-align: left;
        cursor: pointer;
        color: rgb(170, 170, 170);
      }
      
      .listItem:hover {
        background-color: #3e6947;
        color: white;
       
      }
      
      .listItem:hover h2 {
        color: white;
       
      }
      
    
      
      .imgThumbnail {
        margin: 0px auto;
        border-radius: 100%;
        object-fit: cover;
        width: 25px;
        height: 25px;
      }
      .imgThumbnail:hover {
        filter: grayscale(100%);
        cursor: pointer;
      }
    
    
    .imgContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
      
    
      @media all and (max-width: 768px) {
      
        .items {
          width: 100%;
        }
    
        .imgThumbnail {
          width: 20px;
          height: 20px;
        }
      
       
      }
      