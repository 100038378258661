.featuresContainer {
    margin: 0px auto;
    width: 100%;
    padding: 120px 60px 20px 60px;
  
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .autoGrid {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .items {
    width: 100%;
  }
  
  .paragraph {
    width: 70%;
    padding-bottom: 40px;
    text-align: center;
    margin: 0px auto;
  }
  
  .listItem {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  .listItem h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .listItem h2:hover {
    color: #1abc9c;
  }
  
  .listItem p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .listItem:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  


  
  .imgThumbnail {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .imgThumbnail:hover {
    filter: grayscale(100%);
    cursor: pointer;
  }


  

  
  
  @media all and (max-width: 768px) {
    .featuresContainer {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
  
    .items {
      width: 100%;
    }
  
    .paragraph {
      width: 100%;
    }
  
    .imgThumbnail {
      height: 50vh;
    }
  }
  