.featuresContainer {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;
   
  }
  
  .autoGrid {
    --auto-grid-min-size: 18rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .items {
    width: 100%;
  }
  
  .paragraph {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .listItem {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #F7FFEF;
  }
  
  .listItem h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  
  .listItem h2:hover {
    color: #50835a;
  }
  
  .listItem p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .listItem:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .imgThumbnail {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 400;
  }
  .imgThumbnail:hover {
    filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .featuresContainer {
      width: 100%;
      display: block;
    }
  
    .items {
      width: 100%;
    }
  
    .paragraph {
      width: 100%;
     
    }
  
    .imgThumbnail {
      height: 30vh;
    }
  }
  