@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.card {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    padding: 10px 40px;
    padding-bottom: 5px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    
}

.title {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.title:hover {
    color: #008066;
}

.card p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.year {
    color: gray;
    font-size: 13px;
}

.user {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 14px;
    word-wrap: break-word;  
    
}

.user:hover {
    text-decoration: underline;
}

.toolBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:flex-end;
    width: 100%;
    border-top: solid 1px rgb(230, 230, 230);
    padding-top: 3px;
    margin-top: 3px;
}

.toolBox div {
    margin: 5px;
}

@media all and (max-width: 768px) {
    

  
  }