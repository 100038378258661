
.birdInfo {
  display: flex;
  font-family: Roboto;
  box-shadow: 1px 6px 15px rgba(45, 55, 71, 0.285457), 
  -6px -19px 21px rgba(244, 248, 251, 0.345662), 
  -9px -5px 14px rgba(246, 251, 255, 0.741505), 
  -5px -5px 14px rgba(255, 255, 255, 0.0155704);
  min-height: 80vh;
}

.birdImg {
  width: 50%;
  object-fit: cover;
}

.birdStat {
  width: 50%;
  padding: 100px;
  background: #1C2E20;
}

.comName {
  margin-top: 10px;
  font-size: 55px;
  line-height: 50px;
  color: #fdfffb;
}



.map, .content {
  flex-basis: 100%;
  text-align: center;
  /* border: 1px solid black; */
}

.maps {
  width: 100%; 
  object-fit: cover;
  object-position: 20% 20%; 
  display: block;
}

.statusContainer {
    margin-right: 1px;
    margin-top: 22px;
    height: 35px;
    width: 35px
}

.sciName {
  font-size: 22px;
  font-style: italic;
  margin: 20px 0px;
  color: #ffffff;
}

.birdStat .identification p {
  color: white !important;
}

.identification span {
  background-color: transparent !important; 
  color: white !important;
  font-size: 14px;
}



.generalInfo{
  display: flex;
  width: 90%;
  margin: 50px auto;
}

.details {
  padding-right: 40px;
  font-family: Roboto;
}

.media {
  margin-top: 50px;
}

.thumbnails {
  display: flex;
  margin: 10px;
}

.thumbnails img {
  width: 250px;
  margin-right: 10px;
}


.topic {
  display: flex;
  border-bottom: 1px solid rgb(230, 230, 230);
  margin-bottom: 15px;
  margin-top: 20px;
}

.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgb(228, 228, 228);
  border-radius: 4px;
  padding: 5px;
  background: white;
  margin-right: 20px;
  margin-top: -10px;
  width: 70px;
  height: 70px;
  position: relative;
}


/* MEDIA QUERY STARTS HERE */
@media only screen and (max-width: 650px) {


  .generalInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

  }

  .birdInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}
  .imageConatiner {
    width: 100%;
  }

  .birdImg {
      width: 100%;
     
      
  }
  
  .birdStat {
      width: 100%;
      padding: 80px 20px;
  
  }

  .taxonomy {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
      width: 100%;
  }

  .orders, .families, .geniuses {
     
      font-size: 14px;
      border-right: 1px solid rgb(67, 80, 62);
      padding: 0px;
      text-align: center;
      min-width: 80px;
  }

  .comName {
      margin-top: -10px;
      font-size: 38px;
      color: #d3fca4;
      line-height: 35px;
      padding-bottom: 20px;
  }

  .statBox {
      display: none;
  }



}

/* MEDIA QUERY STOPS HERE */