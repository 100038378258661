.featuresContainer {
  margin: 0px auto;
  width: 100%;
  padding: 0px 60px 20px 60px;

 
}

/*
    AUTO GRID
    Set the minimum item size with `--auto-grid-min-size` and you'll
    get a fully responsive grid with no media queries.
  */
.autoGrid {
  --auto-grid-min-size: 16rem;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
}

.items {
  width: 100%;
}

.paragraph {
  width: 60%;
  margin: 0px auto;
  padding-bottom: 40px;
  text-align: center;
}

.listItem {
  padding: 0rem 0rem;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 1.2rem;
  list-style-type: none;
  text-align: left !important;
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  border-radius: 8px;
}

.listItem h2 {
  text-align: center;
  padding: 10px 25px;
  text-align: left;
  font-size: 16px;
  line-height: 23px;
}

.listItem h2:hover {
  color: #426e4b;
}

.listItem p {
  text-align: center;
  padding: 10px 25px;
  text-align: left;
}

.listItem:hover {
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
 
}

.hrLine {
  width: 70%;
  margin: 20px 0px;
  border-top: 1px 0px 0px 0px solid rgb(252, 252, 252);
  height: 1px;
  margin: 15px auto;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgThumbnail {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  height: 30vh;
}
.imgThumbnail:hover {
  filter: grayscale(100%);
  cursor: pointer;
}

.authorAndDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}

.authorAndDate h3 {
  color: #0d9b7f;
  font-size: 10px;
}

.author {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0px;
  
}




.bg {
  background-color: #1ABC9C;
}

.pageNum:focus {
  background-color: #1ABC9C;
  color: white;
}

.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px
}

.loadMoreBtn {
  border: 2px solid #195728;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background-color: white;
  margin-top: 20px;
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  font-family: DM Sans;
}

.loadMoreBtn:hover {
  background-color: #195728;
  color: white;
  border: 2px solid white;
}


.specieInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
}



@media all and (max-width: 768px) {
  .featuresContainer {
    margin: 100px auto;
    width: 100%;
    display: block;
    padding: 5px 8px;
  }

  .paragraph {
    width: 90%;
  }

  .items {
    width: 100%;
  }

  .imgThumbnail {
    height: 30vh;
  }
}
