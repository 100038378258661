.featuresContainer {
  margin: 30px auto;
  margin-top: 80px;
  width: 70%;
  display: flex;
  text-align: center !important;
  justify-content: space-between;
}

.paragraph p {
  text-align: center !important;
}

.hrLine {
  margin: 30px;
}

@media all and (max-width: 768px) {
  .featuresContainer {
    margin: 100px auto;
    width: 95%;
    display: flex;
    flex-direction: column;
  }

  .items {
    width: 100%;
  }

  .paragraph {
    width: 100%;
    padding: 0px 10px;
    padding-bottom: 50px;
  }


  .imgThumbnail {
    height: 30vh;
  }
}
