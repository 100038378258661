.featuresContainer {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;
   
  }
  
  .autoGrid {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .items {
    width: 100%;
  }
  
  .paragraph {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .listItem {
    padding: 0rem 0rem;
    list-style-type: none;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  
  .listItem:hover {
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
    background-color: rgb(245, 255, 242);
    border: solid 2px rgb(199, 255, 182);
   
  }
  
  .imgThumbnail {
    width: 100%;
    object-fit: cover;
    height: 300;
  }
  .imgThumbnail:hover {
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .featuresContainer {
      width: 100%;
      display: block;
    }
  
    .items {
      width: 100%;
    }
  
    .paragraph {
      width: 100%;
     
    }
  
    .imgThumbnail {
      height: 30vh;
    }
  }
  