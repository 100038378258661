.layout {
    margin: 0px auto;
    width: 100%;
    padding: 80px 0px;
    
}

.header {
    text-align: center;
}

.header h1 {
    font-size: 70px;
    line-height: 70px;
    padding: 50px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
}

.header p {
    font-size: 35px;
    line-height: 40px;
    padding: 30px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
}

/* .body {
    width: 70%;
    margin: 0px auto;
    text-align: left;
    line-height: 30px !important;
}

.body p {
    text-align: left;
} */


.section {
    display: grid;
  justify-content: center;
}

  .featuredImage {
        width: 100%;
        height: 500px;
        object-fit: cover;
  }


  .section h1 {
    font-size: 50px;
    line-height: 50px;
    padding: 40px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.section p {
    font-size: 20px;
    line-height: 30px;
    padding: 30px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
}


.pubCharts {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: flex-start;

}

.innerSection {
    width: 100%;
    margin: 0 auto;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    background-color: #3e6947;
    box-shadow:
    2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07)
  ;
}

.pubCharts p {
    font-size: 30px;
    line-height: 40px !important;
    padding:40px;
    line-height: 55px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
    font-weight: 700;
}

.text {
    width: 45%;
    padding: 30px 0px;
    border-radius: 6px;
    border: solid 2px gray;
    box-shadow:
  2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  100px 100px 80px rgba(0, 0, 0, 0.07);  
}

.chart {
    width: 50%;
}

.h1 {
    font-size: 50px;
    line-height: 50px;
    padding: 60px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

@media all and (max-width: 768px) {
    .layout {
        width: 100%;
    }
    

.section h1 {
    font-size: 26px;
    line-height: 30px;
    padding: 10px;
    letter-spacing: 0px;

}


.header h1 {
    font-size: 30px;
    line-height: 40px;
    padding: 10px;

}

.h1 {
    font-size: 23px;
    line-height: 26px;
    padding: 30px 20px;
}

.header p {
    font-size: 18px;
    line-height: 23px;
    padding: 10px;

}


.featuredImage {
    width: 100%;
    height: 20vh;
    object-fit: cover;
}


.pubCharts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: flex-start;

}

.text {
    width: 95%;
}

.chart {
    width: 100%;
}

.section p {
    font-size: 20px;
    line-height: 30px;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
}

}