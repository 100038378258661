@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.card {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    padding: 30px 40px;
    border-radius: 10px;
    margin: 10px auto;
    background-color: white;
    display: flex;
    width: 80%;
}

.title {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.title:hover {
    color: #3e6947;
}

.card p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.year {
    color: gray;
    font-size: 13px;
}

.user {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 14px;
    word-wrap: break-word;  
    
}

.user:hover {
    text-decoration: underline;
}


.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    font-size: 14px;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.button:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}

@media all and (max-width: 768px) {
    
    .card {
        width: 100%;
    }

  
  }