.layout {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.header {
    text-align: left;
}

.header h3 {
    text-align: left !important;
}

.body p {
    text-align: left;
}

.author {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    padding: 8px;
    padding-top: 0px;
  }

  .author h3:hover {
      text-align: left;
  }

  .featuredImage {
        width: 100%;
        height: auto;
  }

  .hrLine {
      padding-bottom: 20px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      height: 1px;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    line-height: 14px;
    
}

.user:hover {
    text-decoration: underline;
}


@media all and (max-width: 768px) {
    .layout {
        width: 80%;
    }
}