.layout {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.header {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.header input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.header input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.searchBar {
    display: flex;
}

.searchBar button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.searchBar button:hover {
    background-color: #33583b;
}

.cardContainer {
    width: 65%;
    margin: 0px auto;
}


.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0px;
    
}


.pageNum {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.bg {
    background-color: #3e6947;
}

.pageNum:focus {
    background-color: #3e6947;
    color: white;
}


.editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 50px;
    padding: 15px 30px 15px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    margin: 10px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
  }

  .btnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
  }


@media all and (max-width: 768px) {
    .featuresContainer {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .paragraph {
      width: 100%;
    }

    .header {
      width: 95%;

  }

  .cardContainer {
    width: 98%;
}

.searchBar button {
    width: 30%;
}
  
  }
