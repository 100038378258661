@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"); */

.MuiTableCell-root {
  padding: 0px 18px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  color: rgb(59, 59, 59) !important;
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #0f2137;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #0f2137;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #0f2137;
}

p {
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  color: #0f2137;
  text-align: left !important;
  background-color: transparent !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input {
  outline: none;
}

.fa-caret-down {
  padding-left: 4px;
  font-size: 12px;
}

/* AWESOME SLIDER SETTINGS */
.awssld {
  --slider-height-percentage: 38% !important;
  --slider-transition-duration: 700ms !important;
  --organic-arrow-thickness: 4px !important;
  --organic-arrow-border-radius: 0px !important;
  --organic-arrow-height: 40px !important;
  --organic-arrow-color: transparent !important;
  --control-button-width: 10% !important;
  --control-button-height: 25% !important;
  --control-button-background: transparent !important;
  --control-bullet-color: transparent !important;
  --control-bullet-active-color: transparent !important;
  --loader-bar-color: #f0ecec !important;
  --loader-bar-height: 0px !important;
  --content-background-color: #f0ecec !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* BUTTONS */
.btn-hover.color-1 {
  margin-left: -2px;
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    #2bb673
  );
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  border: 1px solid white;
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius: 50px;
  /* moz-transition: all 0.4s ease-in-out; */
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  /* moz-transition: all .4s ease-in-out; */
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}


/* FOOTER BOTTOM ICONS */
.fab {
  padding-right: 20px;
  font-size: 20px;
  color: rgb(66, 66, 66);
}

/* QUILL EDITOR STYLING */
.text-editor {
  width: 98%;
  margin: 7px auto;
}
.ql-editor {
  min-height: 150px;
  border: none;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 20px;
  color: rgb(107, 107, 107);
}

/* TABLE STYLING */
/* table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

table tr:hover {
  background-color: rgb(245, 245, 245);
}





/* PURE CSS ONLY TAB STYLING */
.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset {
  margin: 0 auto;
}

.tabset .tab-panel {
  display: none;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

/*
 Styling
*/


.tabset > label {
  position: relative;
  display: inline-block;
  padding: 30px 40px 25px;
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  margin-top: 30px;
  background-color: rgb(247, 247, 247);
  
}

.tabset > label:hover,
.tabset > input:focus + label {
  color: rgb(0, 139, 7);
}

.tabset > label:hover::after,
.tabset > input:focus + label::after,
.tabset > input:checked + label::after {
  background: #06c;
}

.tabset > input:checked + label {
  border-color: #ccc;
  border-bottom: 1px solid #fff;
  background-color: white;
  
}

.tab-panel {
  padding: 30px 0;
  border-top: 1px solid #ccc;
}



.tabset {
  max-width: 65em;
}




/* //RESPONSIVE NAV MENU */

.nav-transparent{
  position: fixed;
  z-index: 99;
  width: 100%;
  background: #3e6947;  /* #242526; */
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid rgb(122, 122, 122); */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  
}

.nav{
  position: fixed;
  z-index: 99;
  width: 100%;
  background: #3e6947;  /* #242526; */
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid rgb(145, 145, 145); */
  
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  
}
nav .wrapper{
  position: relative;
  max-width: 1300px;
  padding: 0px 20px;
  padding-right: 0px;
  height: 50px;
  line-height: 70px;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.wrapper .logo a{
  color: #dadada;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.wrapper .nav-links{
  display: -webkit-inline-flex;
  display: inline-flex;
}

.nav-links li{
  list-style: none;
}
.nav-links li a{
  color: #dfdfdf;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  padding: 16px 22px;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
}
.nav-links li a:hover{
  background: #253b2a;
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  background: #ffffff;
  width: 220px;
  line-height: 45px;
  top: 80px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 52px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 5px 0 5px 15px;
  font-weight: 400;
  border-radius: 0px;
  color: #383838;
}

.drop-menu li a:hover{
  color: #ffffff;
}

.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 0px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content{
  background: #ffffff;
  padding: 25px 20px;
  display: -webkit-flex;
  display: flex;
  width: 95%;
  margin: 0 auto;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-box .content .row{
  width: calc(25% - 30px);
  line-height: 45px;
}


.content .row img{
  width: 70%;
  height: 70%;
  object-fit: cover;
}
.content .row header{
  color: #383838;
  font-size: 18px;
  font-weight: 500;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: #383838;
  font-size: 14px;
  display: block;
}
.row .mega-links li a:hover{
  color: #f2f2f2;
}
.wrapper .btn{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn{
    display: block;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 65%;
    /* Change the width of mobile menu */
    max-width: 100%;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 30px 10px;
    line-height: 30px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }
  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 7px;
    display: block;
    font-size: 14px;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    /* padding-left: 20px; */
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop1:checked ~ .drop-menu,
  #showDrop2:checked ~ .drop-menu,
  #showDrop3:checked ~ .drop-menu,
  #showDrop4:checked ~ .drop-menu,
  #showDrop5:checked ~ .drop-menu,
  #showDrop6:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box{
  max-height: 100%;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #f2f2f2;
    font-size: 14px;
    font-weight: 400;
    padding: 7px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background: #3A3B3C;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    /* border-radius: 5px; */
    font-size: 14px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
}
nav input{
  display: none;
}

.body-text{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div{
  font-size: 45px;
  font-weight: 600;
}





/* ACCORDION STYLING FOR CURRICULUM */
.accordion {
  width: 60%;
  margin: 0px auto;
  margin-top: -50px;
}
.accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.closer{
  display:none;
}
.tab {
  width: 100%;
  color: #1a252f;
  overflow: hidden;
  margin: 5px;
}
.display-flex{
  display:-webkit-flex;
  display:flex;
}
.tab-label {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1em;
  background: #f3f3f3;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.tab-label:hover {
  background: #dbdbdb;
}
.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all .35s;
}
.tab-close {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #cacaca;
  cursor: pointer;
}
.tab-close:hover {
  background: #d1d1d1;
}

.collapser:checked + .tab-label {
  background: #d3d3d3;
}
.collapser:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.collapser:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

@media screen and (max-width: 970px) {
  .accordion {
    width: 95%;
  }
}






/* NEW RESPONSIVE NAVIAGATION BAR */
/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */

.container {
  width: 100%;
	padding-right:0px;
	padding-left:15px;
	margin-right:auto;
	margin-left:auto;
  height: 50px;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
}
img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding: 50px 0;
    background-color: #3E6947;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 50px;
}
.is-active {
  color: #ff5800!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: -webkit-inline-flex;
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  position: relative;
  display: block;
  color: #D2D3D2;
  /* change the width of menu items */
  padding: 5px 25px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 20%;
  display: inline-block;
  float: left;
}
.header__middle__menus {
  width: 80%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 0%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  float: left;
  position: relative;
  background-color: #3e6947;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 0px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  width: 227px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

.sub__menus a {
  color: #000000;
}

.sub__menus a:hover {
  color: #ffffff;
}


@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px;
  /* margin: 7px 27px; */
}
.menu-item:hover > a{
  background: #253b2a !important;
}
.menu-item .sub__menus li:hover a{
  background: #253b2a !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 5px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #d8f1ff;
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}



/*CUSTOM  READ MORE STYLING FOR ABOUT PAGE*/

.entry {
  max-width: 50%;
  margin: 0 auto;
}


@media (max-width:767px) { 
  .entry {
    max-width: 90%;
  }
}

.read-more-wrap {
    position:relative;
}

.read-more-input {
  display: none;
}


.read-more-target p {
  text-align: justify !important;
}

.read-more-target h2, h3 {
  text-align: center !important;
}

.bio {
  text-align: center !important;
}

.read-more-input:checked ~ .read-more-wrap .read-more-target {
  max-height: 400px;
  font-size: inherit;
  text-align: left;
}

.read-more-target {
  display: inline-block;
  transition: .3s ease;
  max-height: 30px;
  overflow: hidden;
  text-align: left;
}

.read-more-trigger {
  position: relative;
}

.read-more-input ~ .read-more-trigger:before {
  content: 'Read more';
  speak: 'Read less';
}

.read-more-input:checked ~ .read-more-trigger:before {
  content: 'Read less';
  speak: 'Read less';
}

.read-more-input:checked ~ .read-more-trigger:after {
  padding: 0;
  margin: 0;
}

.read-more-trigger {
  transition: all 0.3s;
  cursor: pointer;
  display: block;
  padding: 0.5rem .5em;
  color: rgb(74, 105, 207);
  font-size: .9em;
  line-height: 2;
  font-size: 16px;
  text-decoration: underline;
  /* border: 1px solid #ddd; */
  border-radius: .25em;
  width: 30%;
  margin: 10px auto;
}

.read-more-trigger:after {
  transition: all 0.3s ease;
  content: "";
  speak: none;
  position: absolute;
  top: 0;
  left: -1px;
  width: 100vw;
  margin-top: -50px;
  padding-top: 40px;
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 1) 97%); */
  width: 30%;
  margin: 0 auto;
}


.iframe-loading {
  background:url('https://icon-library.com/images/loading-gif-icon/loading-gif-icon-9.jpg') center center no-repeat;
}
.Footer_featuresContainer__3CGTG {
  margin: 0px auto;
  padding-top: 20px;
  background-color: rgb(241, 241, 241);
}


.Footer_bottom__w8ly4 {
  border-top: 1px solid gray;
  padding-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Footer_soc__3pY07 {
  text-align: left !important;
}
/*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
.Footer_autoGrid__3nPfc {
  --auto-grid-min-size: 12rem;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 0.5fr)
  );
  grid-gap: 1rem;
}

.Footer_items__3u1su {
  width: 95%;
  margin: 0px auto;
}

.Footer_footerBottom__1Awa9 {
  width: 100%;
  padding: 30px 10%;
  text-align: center;
}

.Footer_listItem__5GzU8 {
  padding: 1rem 0rem;
  text-align: center;
  font-size: 0.9rem;
  list-style-type: none;
  text-align: left !important;
  font-family: "DM Sans", sans-serif;
  line-height: 35px;

  /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  border-radius: 8px; */
}

.Footer_logoContainer__3tWkv {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Footer_listItem__5GzU8 h2 {
  text-align: center;
  padding: 15px 0px;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
}

/* .listItem h2:hover {
  color: #1abc9c;
} */

.Footer_listItem__5GzU8 p {
  text-align: center;
  padding: 10px 25px;
  text-align: left;
}

.Footer_listItem__5GzU8 li {
  list-style-type: none;
}

.Footer_hrLine__1vjN9 {
  width: 100%;
  margin: 20px 0px;
  border-top: 1px 0px 0px 0px solid rgb(122, 122, 122);
  height: 1px;
  margin: 15px auto;
}

.Footer_logoContainer__3tWkv {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Footer_imgThumbnail__2q-Jm {
  width: 60%;
  object-fit: cover;
}
/* .imgThumbnail:hover {
  filter: grayscale(100%);
} */

.Footer_authorAndDate__1wX5M h3 {
  color: #0d9b7f;
}

@media all and (max-width: 768px) {
  .Footer_featuresContainer__3CGTG {
    padding: 20px;
    padding-top: 5px;
    width: 100%;
    display: block;
  }

  .Footer_items__3u1su {
    width: 100%;
    margin: 0px auto;
  }

  .Footer_paragraph__26TIP {
    width: 100%;
    padding-left: 50px;
  }
}

.Features_featuresContainer__1-J7e {
  margin: 50px auto;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Features_imgThumbnail__Jt4UO {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  height: 200px;
}

/*
  AUTO GRID
  Set the minimum item size with `--auto-grid-min-size` and you'll
  get a fully responsive grid with no media queries.
*/
.Features_autoGrid__2i2OG {
  --auto-grid-min-size: 12rem;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 0.5fr)
  );
  grid-gap: 1rem;
}

.Features_items__2xK83 {
  width: 50%;
}

.Features_paragraph__2m7SF {
  width: 50%;
  padding-right: 20px;
}

.Features_paragraph__2m7SF p {
  text-align: left;
}

.Features_listItem__1KDrg {
  padding: 0rem 0rem 2rem 0rem;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  list-style-type: none;
  border: 1px solid rgb(223, 223, 223);
  /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
}

.Features_listItem__1KDrg:hover {
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  border: 1px solid #83e799;
  cursor: pointer;
}

.Features_listItem__1KDrg p {
  text-align: left;
}

.Features_listItem__1KDrg h2 {
  text-align: center;
  font-size: 16px;
  color: rgb(71, 71, 71);
}

.Features_listItem__1KDrg:hover {
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  cursor: pointer;
}

.Features_hrLine__rOUz3 {
  width: 70%;
  margin: 20px 0px;
  border-top: 1px 0px 0px 0px solid rgb(218, 218, 218);
  height: 1px;
}

.Features_logoContainer__neuiK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media all and (max-width: 768px) {
  .Features_featuresContainer__1-J7e {
    margin: 100px auto;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Features_items__2xK83 {
    width: 100%;
  }



  .Features_autoGrid__2i2OG {
    --auto-grid-min-size: 15rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }

  .Features_paragraph__2m7SF {
    width: 100%;
    padding: 0px 10px;
    padding-bottom: 50px;
  }


  .Features_imgThumbnail__Jt4UO {
    height: 30vh;
  }
}


.Features_button__1S30b {
  float: left;
  padding: 12px 16px;
  border-radius: 4px;
  border: solid none;
  cursor: pointer;
  background-color: #3e6947;
  color: white;
  box-shadow:
0 2.8px 2.2px rgba(0, 0, 0, 0.02),
0 6.7px 5.3px rgba(0, 0, 0, 0.028),
0 12.5px 10px rgba(0, 0, 0, 0.035),
0 22.3px 17.9px rgba(0, 0, 0, 0.042),
0 41.8px 33.4px rgba(0, 0, 0, 0.05),
0 100px 80px #00000012;
margin-top: 30px;

}

.Features_button__1S30b:hover {
  background-color: white;
  color: black;
  border: solid 1px #3e6947;
}


.Hero_bigImage__1465Y {
  height: 85vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  /* background-image: url("https://i.postimg.cc/YS4YNCp2/Amurum-2.jpg"); */
}

.Hero_overlay__2g9tD {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(16, 39, 27, 0.575);
  /* background: rgba(0, 0, 0, 0.65); */
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Hero_content__G3XUD {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    margin-left: 20%;
}

.Hero_content__G3XUD img {
    height: 130px;
    width: 130px;
    margin-right: 40px;
}

.Hero_overlay__2g9tD h1 {
  font-size: 50px;
  line-height: 45px;
  letter-spacing: 0px;
  font-weight: 700px;
  color: white;
  text-align: left;
  width: 70%;
  /* font-family: Roboto; */
  /* font-weight: 100px !important; */
}


.Hero_overlay__2g9tD p {
  margin: 0;
    color: white;
    text-align: left;
}

.Hero_buttonContainer__vdTSQ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 10px;
  margin-top: 20px;
}

.Hero_button__WeDEz {
  padding: 15px 20px;
  border-radius: 50px;
  border: solid 2px #ffffff;
  cursor: pointer;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
  color: white;

}

.Hero_button__WeDEz:hover {
  background-color: #3E6947;
  color: white;
  border: solid 1px #3E6947;
}


@media all and (max-width: 768px) {
  .Hero_overlay__2g9tD h1 {
    font-size: 25px;
    line-height: 25px;
    width: 90%;
    
  }

  .Hero_overlay__2g9tD p {
    margin: 0;
    width: 70%;
     
  }
}

.Spinner_loader__3ENkM {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3e6947;
  width: 150px;
  height: 150px;
  -webkit-animation: Spinner_spin__2cA5G 2s linear infinite; /* Safari */
  animation: Spinner_spin__2cA5G 2s linear infinite;
  
}

/* Safari */
@-webkit-keyframes Spinner_spin__2cA5G {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__2cA5G {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.Spinner_container__3wPbR {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 100px;
}

.RecentNews_featuresContainer__3ZJoP {
  margin: 0px auto;
  width: 100%;
  padding: 0px 60px 20px 60px;

 
}

/*
    AUTO GRID
    Set the minimum item size with `--auto-grid-min-size` and you'll
    get a fully responsive grid with no media queries.
  */
.RecentNews_autoGrid__lep5I {
  --auto-grid-min-size: 16rem;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
}

.RecentNews_items__1t8R0 {
  width: 100%;
}

.RecentNews_paragraph__2_Pwq {
  width: 60%;
  margin: 0px auto;
  padding-bottom: 40px;
  text-align: center;
}


.RecentNews_paragraph__2_Pwq p {
  text-align: center !important;
}

.RecentNews_listItem__23bgn {
  padding: 0rem 0rem;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 1.2rem;
  list-style-type: none;
  text-align: left !important;
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  border-radius: 8px;
}

.RecentNews_listItem__23bgn h2 {
  /* font-family: 'Roboto', sans-serif;
  font-weight: 500; */
  text-align: center;
  padding: 10px 25px;
  text-align: left;
  font-size: 15px;
  line-height: 23px;
}

.RecentNews_listItem__23bgn h2:hover {
  color: #426e4b;
}

.RecentNews_listItem__23bgn p {
  text-align: center;
  padding: 10px 25px;
  text-align: left;
}

.RecentNews_listItem__23bgn:hover {
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
 
}

.RecentNews_hrLine__1Xh-C {
  width: 70%;
  margin: 20px 0px;
  border-top: 1px 0px 0px 0px solid rgb(252, 252, 252);
  height: 1px;
  margin: 15px auto;
}

.RecentNews_logoContainer__1VAx1 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.RecentNews_imgThumbnail__1Gyvv {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  height: 200px;
}
.RecentNews_imgThumbnail__1Gyvv:hover {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: pointer;
}

.RecentNews_authorAndDate__2kTx6 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0px 15px;
}

.RecentNews_authorAndDate__2kTx6 h3 {
  color: #0d9b7f;
  font-size: 10px;
}

.RecentNews_author__1KNst {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.RecentNews_pagination__19a9C {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 45px 0px;
  
}




.RecentNews_bg__1ONoU {
  background-color: #1ABC9C;
}

.RecentNews_pageNum__1GM8w:focus {
  background-color: #1ABC9C;
  color: white;
}

.RecentNews_loadMore__13tsf {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 30px
}

.RecentNews_loadMoreBtn__1ui2i {
  float: left;
  padding: 18px 24px;
  border-radius: 4px;
  border: solid none;
  cursor: pointer;
  background-color: #3e6947;
  color: white;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px #00000012;
  margin: 30px;
  border: solid 1px #3e6947;
}

.RecentNews_loadMoreBtn__1ui2i:hover {
  background-color: #195728;
  color: white;
  border: 2px solid white;
}


@media all and (max-width: 768px) {
  .RecentNews_featuresContainer__3ZJoP {
    margin: 100px auto;
    width: 100%;
    display: block;
    padding: 5px 8px;
  }

  .RecentNews_paragraph__2_Pwq {
    width: 90%;
  }

  .RecentNews_items__1t8R0 {
    width: 100%;
  }

  .RecentNews_imgThumbnail__1Gyvv {
    height: 30vh;
  }
}




.Weather_featuresContainer__2NAig {
    margin-top: -50px;
    z-index: 10;
    position: relative;
    width: 95vw;
    padding-left: 40px;
    
  }
  
  
  /*
    AUTO GRID
    Set the minimum item size with `--auto-grid-min-size` and you'll
    get a fully responsive grid with no media queries.
  */
  .Weather_autoGrid__1Tabt {
    --auto-grid-min-size: 10rem;
    width: 100%;
    margin: 0px auto;
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 0.5fr)
    );
    grid-gap: 0rem;
  }
  
  
  
  .Weather_paragraph__SRe8y p {
    text-align: left;
  }
  
  .Weather_listItem__3whN_ {
    padding: 0rem 0rem 2rem 0rem;
    text-align: center;
    /* font-size: 18px; */
    list-style-type: none;
    background-color: white;
    /* border: 1px solid rgb(235, 235, 235); */
  }
  
  .Weather_listItem__3whN_ .Weather_svg__23j1F {
    padding-top: 1.5rem;
  }
  

  .Weather_listItem__3whN_:hover {
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
    cursor: pointer;
    background-color: #eeffdb;
  }
  
  .Weather_listItem__3whN_ p {
    text-align: center !important;
    font-family: Roboto;
    font-size: 30px;
  }
  
  .Weather_listItem__3whN_ h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #3e6947
  }
  
  .Weather_listItem__3whN_:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    cursor: pointer;
  }
  
  .Weather_hrLine__Wbxxp {
    width: 70%;
    margin: 20px 0px;
    border-top: 1px 0px 0px 0px solid rgb(218, 218, 218);
    height: 1px;
  }
  
  /* .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  
  @media all and (max-width: 768px) {
    .Weather_featuresContainer__2NAig {
      margin: 0px auto;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 0px;
    }
  
    .Weather_items__2cLyR {
      width: 100%;
    }
  
    .Weather_paragraph__SRe8y {
      width: 100%;
      padding: 0px 10px;
      padding-bottom: 50px;
    }
  
  
    .Weather_autoGrid__1Tabt {
      --auto-grid-min-size: 8rem;
      width: 100%;
    }
  }
.Spinner_loader__KslbU {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3e6947;
  width: 30px;
  height: 30px;
  -webkit-animation: Spinner_spin__1Eg6h 2s linear infinite; /* Safari */
  animation: Spinner_spin__1Eg6h 2s linear infinite;
  
}

/* Safari */
@-webkit-keyframes Spinner_spin__1Eg6h {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__1Eg6h {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.Spinner_container__1rbkZ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.CapacityBuilding_featuresContainer__3s37B {
    margin: 0px auto;
    /* background-image: url("https://res.cloudinary.com/wabis/image/upload/v1636710062/special/Capacity-Building-cleaned_f09ajt.jpg");
    background-color: #cccccc; */
    margin-bottom: 50px;
   
  }
  
  .CapacityBuilding_autoGrid__1nbjj {
    --auto-grid-min-size: 30rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 0rem;
  }

  /* For Projectors or Higher Resolution Screens (Full HD) */
  /* @media screen and (min-width: 1280px) {
    .autoGrid {
      --auto-grid-min-size: 70rem;
    }
    } */
  
  .CapacityBuilding_items__12YAi {
    width: 100%;
    background: #eafada;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }


  
  
  .CapacityBuilding_paragraph__3eSG0 {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .CapacityBuilding_listItem__10zYo {
    padding: 0rem 0rem;
    padding-bottom: 0rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    background-color: #eafada;
  }
  
  .CapacityBuilding_listItem__10zYo h2 {
    text-align: left;
    padding: 10px 25px;
    font-size: 45px;
    line-height: 50px;
    font-family: 'Roboto', sans-serif;
  }
  
  .CapacityBuilding_listItem__10zYo h2:hover {
    color: #50835a;
  }
  
  .CapacityBuilding_listItem__10zYo ul li p {
    text-align: left !important;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 22px;
  }
  
  .CapacityBuilding_listItem__10zYo:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .CapacityBuilding_imgThumbnail__3vbro {
    width: 100%;
    /* border-radius: 8px 8px 0px 0px; */
    object-fit: cover;
    /* height: 100vh; */
  }
  .CapacityBuilding_imgThumbnail__3vbro:hover {
    -webkit-filter: brightness(95%);
            filter: brightness(95%);
    cursor: pointer;
  }
  


  .CapacityBuilding_button__3aQIL {
    float: left;
    padding: 12px 16px;
    border-radius: 4px;
    border: solid none;
    cursor: pointer;
    background-color: #3e6947;
    color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px #00000012;
  margin: 30px;

}

.CapacityBuilding_button__3aQIL:hover {
    background-color: white;
    color: black;
    border: solid 1px #3e6947;
}
  
  
  @media all and (max-width: 768px) {
    .CapacityBuilding_featuresContainer__3s37B {
      width: 100%;
      display: block;
    }

    .CapacityBuilding_autoGrid__1nbjj {
      --auto-grid-min-size: 15rem;

    }
  
    .CapacityBuilding_items__12YAi {
      width: 100%;
    }
  
    .CapacityBuilding_listItem__10zYo h2 {
      text-align: left;
      padding: 10px 25px;
      font-size: 25px;
      line-height: 30px;
    }

    .CapacityBuilding_paragraph__3eSG0 {
      width: 100%;
     
    }
  
    .CapacityBuilding_imgThumbnail__3vbro {
      height: 50vh;
    }
  }
  
.Profile_container__WdMDr {
  text-align: center;
}

.Profile_warningContainer__MK5XW {
  padding: 100px 200px;
  margin: 100px;
  margin: 0px auto;
  width: 80%;
 

}

.Profile_warningContent__2aS-7 {

  padding: 50px;
  border: solid 1px gray;
  border-radius: 6px;
}

.Profile_containerHead__1aPri {
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 220px;

  /* background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(12, 12, 29, 0.6306897759103641) 38%,
    rgba(0, 212, 255, 1) 100%
  ); */
}

.Profile_menu__2nCtR p {
  padding: 0px;
}

.Profile_profilePicture__3I-Zu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Profile_works__3ijhk {
  padding: 10px;
  border-top: 1px solid rgb(226, 226, 226);
  border-bottom: 1px solid rgb(226, 226, 226);
  width: 50%;
  margin: 10px auto;
  color: gray;
}

.Profile_profilePicture__3I-Zu img {
  height: 225px;
  width: 225px;
  border-radius: 100%;
  margin-top: -60px;
  object-fit: cover;
  border: solid 10px rgb(238, 237, 237);
}

.Profile_containerBody__3aPXx {
  padding-left: 20px;
}

.Profile_name__1UefY {
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  padding-top: 40px;
}


.Profile_location__2CXe5 {
  color: rgb(12, 114, 46);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Profile_bio__BgGXW p {
  padding: 20px;
  width: 50%;
  margin: 0px auto;
}

.Profile_stats__3nNca {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 50%;
  margin: 0px auto;
  padding-bottom: 30px;
}

.Profile_stats__3nNca p {
  color: gray;
  font-size: 12px;
  margin-bottom: -10px;
  text-transform: uppercase;
}

.Profile_statBox__3670- {
  margin: 10px;
}

.Profile_buttons__3S8_2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

/* ADD NEW DROPDOWN BUTTON */

.Profile_dropdown__3kKXs {
  display: inline-block;
  position: relative;
  margin: 10px;
}

.Profile_ddButton__1l2-q {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px 30px 10px 20px;
  background-color: #ffffff;
  cursor: pointer;
}

.Profile_ddButton__1l2-q:after {
  /* content: ""; */
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.Profile_ddButton__1l2-q:hover {
  background-color: #eeeeee;
}

.Profile_ddInput__2teEg {
  display: none;
}

.Profile_ddMenu__durQd {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.Profile_ddInput__2teEg + .Profile_ddMenu__durQd {
  display: none;
}

.Profile_ddInput__2teEg:checked + .Profile_ddMenu__durQd {
  display: block;
}

.Profile_ddMenu__durQd li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.Profile_ddMenu__durQd li:hover {
  background-color: #f6f6f6;
}

.Profile_ddMenu__durQd li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.Profile_ddMenu__durQd li.Profile_divider__1hf68 {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.Profile_edit__2j79y {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.Profile_editButton__1yCRL {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid gray;
  border-radius: 50px;
  padding: 12px 30px 12px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07);
  
}

.Profile_editButton__1yCRL:hover {
  background-color: #1abc9c;
  border: solid 1px white;
  
}



.Profile_contactButton__33KDY {
  padding: 9px 13px;
  border-radius: 50px;
  border: solid 1px gray;
  cursor: pointer;
  font-size: 12px;
  background-color: white;
  margin: 8px;
  

}

.Profile_contactButton__33KDY:hover {
  background-color: #3E6947;
  color: white;
  border: solid 1px #3E6947;
}

.Profile_contactButtons__2vJ0i {
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}


@media all and (max-width: 768px) {
  .Profile_bio__BgGXW p {
    padding: 20px;
    width: 95%;
    margin: 0px auto;
  }

  .Profile_buttons__3S8_2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  
.Profile_stats__3nNca {
  width: 95%;
}

.Profile_warningContainer__MK5XW {
  padding: 0px;
  padding-top: 100px;
  margin: 0px auto;
  width: 100%;
 

}

.Profile_warningContent__2aS-7 {
  padding: 10px;
  border: solid 1px gray;
  border-radius: 6px;
}

}

.StatusIcons_container__1sjY6 {
    display: block;
    position: relative;
    padding: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .StatusIcons_container__1sjY6 p {
    padding-left: 25px;
    text-align: left;
    font-size: 14px;
    color: gray;
  }
  
  /* Hide the browser's default radio button */
  .StatusIcons_container__1sjY6 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .StatusIcons_checkmark__MwohK {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin-top: 10px;
    background-color: #008d3f;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
   /* Create a custom radio button */
   .StatusIcons_checkmark2__1sSDi {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin-top: 10px;
    background-color: #ffaf01;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  /* On mouse-over, add a grey background color */
  .StatusIcons_container__1sjY6:hover input ~ .StatusIcons_checkmark__MwohK {
    background-color: #00642d;
  }
 
    /* On mouse-over, add a grey background color */
    .StatusIcons_container__1sjY6:hover input ~ .StatusIcons_checkmark2__1sSDi {
        background-color: #be8200;
      }
     
      
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .StatusIcons_checkmark__MwohK:after {
    content: "";
    position: absolute;
    display: none;
  }

  .StatusIcons_checkmark2__1sSDi:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .StatusIcons_container__1sjY6 input:checked ~ .StatusIcons_checkmark__MwohK:after {
    display: block;
  }

    /* Show the indicator (dot/circle) when checked */
    .StatusIcons_container__1sjY6 input:checked ~ .StatusIcons_checkmark2__1sSDi:after {
        display: block;
      }
      
  

  /* Style the indicator (dot/circle) */
  .StatusIcons_container__1sjY6 .StatusIcons_checkmark__MwohK:after {
    left: 8px;
    top: 0px;
    width: 6px;
    height: 16px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

    /* Style the indicator (dot/circle) */
    .StatusIcons_container__1sjY6 .StatusIcons_checkmark2__1sSDi:after {
        left: 8px;
        top: 0px;
        width: 6px;
        height: 16px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
  

.AddPost_formContainer__3UCzg {
  width: 70%;
  margin: 0px auto;
  font-family: "DM Sans", sans-serif !important;
}

.AddPost_postTypes__2KzVn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 15px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.AddPost_viewPdf__3Nl0b {
  padding: 20px;
  border: solid 1px red;
  margin: 10px;
  border-radius: 4px;
}


.AddPost_viewPdf__3Nl0b:hover {
  background-color: rgb(233, 233, 233);
}


.AddPost_files__2G1Oj {
  display: -webkit-flex;
  display: flex;
}
.PostType_container__2F8vk {
  display: block;
  position: relative;
  padding: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PostType_container__2F8vk p {
  padding-left: 35px;
  margin-top: -3px;
  text-align: left;
}

/* Hide the browser's default radio button */
.PostType_container__2F8vk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.PostType_checkmark__MOlxO {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  background-color: #eee;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* On mouse-over, add a grey background color */
.PostType_container__2F8vk:hover input ~ .PostType_checkmark__MOlxO {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.PostType_container__2F8vk input:checked ~ .PostType_checkmark__MOlxO {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.PostType_checkmark__MOlxO:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.PostType_container__2F8vk input:checked ~ .PostType_checkmark__MOlxO:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.PostType_container__2F8vk .PostType_checkmark__MOlxO:after {
  left: 13px;
  top: 6px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Uploader_dropzone__1UJtw {
  height: 4rem;
  width: 100%;
  margin: 0.5rem;
  padding: 1rem;
  border: 2px dashed rgb(182, 182, 182);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.Uploader_active__2j7c8 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: rgb(21, 88, 233);
  color: white;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 5px;
}


.UpdateProfile_editButton__2Rjf7 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid gray;
  border-radius: 50px;
  padding: 12px 30px 12px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07);
  
}

.UpdateProfile_editButton__2Rjf7:hover {
  background-color: #1abc9c;
  border: solid 1px white;
  
}
  

.ProfileImage_profilePicture__37BL2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
  
.ProfileImage_fileUpload__2G81e label {
  display: -webkit-flex;
  display: flex;
  background-color: rgb(235, 235, 235);
  color: rgb(65, 65, 65);
  border: 1px gray dashed;
  padding: 0.8rem 0rem 0rem 0.9rem;
  font-family: sans-serif;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  width: 150px;
  height: 150px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
  
  .ProfileImage_fileUpload__2G81e label:hover {
    background-color: rgb(228, 228, 228);
  }
  
  .ProfileImage_upload__2EL7z {
    display: -webkit-flex;
    display: flex;
    border: solid 1px rgb(175, 175, 175);
    width: 90%;
    margin: 20px;
    border-radius: 0.7rem;
  }


  .ProfileImage_uploads__1iIbO {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  
  .ProfileImage_upload__2EL7z p {
    width: 80%;
    padding-left: 15px;
  }
  
  .ProfileImage_profilePic__D3ROE {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 6px rgb(241, 241, 241) solid;
    
  }

  .ProfileImage_profilePicContainer__OIBQ9 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
.Users_container__1kl4r {
    text-align: center;
  }
  
  .Users_containerHead__2_8hy {
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 220px;
  
    /* background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(12, 12, 29, 0.6306897759103641) 38%,
      rgba(0, 212, 255, 1) 100%
    ); */
  }
  
  .Users_menu__232mB p {
    padding: 0px;
  }
  
  .Users_profilePicture__2jtDV {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .Users_profilePicture__2jtDV img {
    height: 225px;
    width: 225px;
    border-radius: 100%;
    margin-top: -60px;
    object-fit: cover;
    border: solid 10px rgb(238, 237, 237);
  }
  

  .Users_name__JvH4p {
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    padding-top: 40px;
  }

  .Users_works__1_VRy {
    padding: 10px;
    border-top: 1px solid rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
    width: 50%;
    margin: 10px auto;
    color: gray;
  }
  
  .Users_location__1y2cE {
    color: rgb(12, 114, 46);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .Users_bio__10pJ8 p {
    padding: 20px;
    width: 50%;
    margin: 0px auto;
  }
  
  .Users_stats__2p-Ky {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 50%;
    margin: 0px auto;
    padding-bottom: 30px;
  }
  
  .Users_stats__2p-Ky p {
    color: gray;
    font-size: 12px;
    margin-bottom: -10px;
    text-transform: uppercase;
  }
  
  .Users_statBox__cRPDd {
    margin: 10px;
  }
  
  .Users_buttons__1qjmh {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  /* ADD NEW DROPDOWN BUTTON */
  
  .Users_dropdown__1uibt {
    display: inline-block;
    position: relative;
    margin: 10px;
  }
  
  .Users_ddButton__3qR1r {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .Users_ddButton__3qR1r:after {
    /* content: ""; */
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  
  .Users_ddButton__3qR1r:hover {
    background-color: #eeeeee;
  }
  
  .Users_ddInput__1jIk5 {
    display: none;
  }
  
  .Users_ddMenu__3e7WR {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
  }
  
  .Users_ddInput__1jIk5 + .Users_ddMenu__3e7WR {
    display: none;
  }
  
  .Users_ddInput__1jIk5:checked + .Users_ddMenu__3e7WR {
    display: block;
  }
  
  .Users_ddMenu__3e7WR li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .Users_ddMenu__3e7WR li:hover {
    background-color: #f6f6f6;
  }
  
  .Users_ddMenu__3e7WR li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  
  .Users_ddMenu__3e7WR li.Users_divider__1MuVt {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
  
  .Users_editButton__3ntWQ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    margin: 10px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
  }
  


  @media all and (max-width: 768px) {
    .Users_bio__10pJ8 p {
      padding: 20px;
      width: 95%;
      margin: 0px auto;
    }
  
  }

.Publications_cardContainer__1fkq_ {
    width: 70%;
    margin: 0px auto;
}

@media all and (max-width: 768px) {
    .Publications_cardContainer__1fkq_ {
      margin: 5px auto;
      width: 97%;
      display: block;
    }
}
.Card_card__150V2 {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    /* box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1); */
    padding: 30px 40px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
}

.Card_title__2pmX2 {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    word-wrap: break-word;
    
}


.Card_title__2pmX2:hover {
    color: #3e6947;
}

.Card_card__150V2 p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__f9qJx {
    color: gray;
    font-size: 13px;
    word-wrap: break-word;
}

.Card_Avatar__1UFkj {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 20px;
}

.Card_user__Q3o0H {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    cursor: pointer;
    line-height: 10px;
}

.Card_user__Q3o0H:hover {
    text-decoration: underline;
}


@media all and (max-width: 768px) {
    .Card_card__150V2 {
   
      margin: 3px;
    }
  
  }
.News_cardContainer__3pAit {
    width: 65%;
    margin: 0px auto;
}


 
@media all and (max-width: 768px) {
    .News_cardContainer__3pAit {
      margin: 10px auto;
      width: 90%;
      display: block;
    }
}
.Card_card__1xZhw {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
}

.Card_title__26xXk {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.Card_title__26xXk:hover {
    color: #008066;
}

.Card_card__1xZhw p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__2SaZ9 {
    color: gray;
    font-size: 13px;
}

.Card_Avatar__1apK_ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 20px;
}
.Document_cardContainer__AL4W9 {
    width: 65%;
    margin: 0px auto;
}


 
@media all and (max-width: 768px) {
    .Document_cardContainer__AL4W9 {
      margin: 10px auto;
      width: 90%;
      display: block;
    }
}
.Card_card__mDOhc {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
}

.Card_title__BypO9 {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.Card_title__BypO9:hover {
    color: #008066;
}

.Card_card__mDOhc p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__bc-Ba {
    color: gray;
    font-size: 13px;
}

.Card_Avatar__2dZog {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 20px;
}
.PubDetails_layout__3ahin {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.PubDetails_header__3sxef {
    text-align: left;
}

.PubDetails_header__3sxef h3 {
    text-align: left !important;
}

.PubDetails_body__86EDp p {
    text-align: left;
}

.PubDetails_author__TyLWI {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: left;
            justify-content: left;
    padding: 8px;
    padding-top: 0px;
  }

  .PubDetails_author__TyLWI h3:hover {
      text-align: left;
  }

  .PubDetails_featuredImage__1lunn {
        width: 100%;
        height: auto;
  }

  .PubDetails_hrLine__1OHKB {
      padding-bottom: 20px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      height: 1px;
  }

  .PubDetails_user__2Lb1o {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    cursor: pointer;
    line-height: 14px;
    
}

.PubDetails_user__2Lb1o:hover {
    text-decoration: underline;
}


@media all and (max-width: 768px) {
    .PubDetails_layout__3ahin {
        width: 80%;
    }
}
.ProposalDetail_layout__2ORL1 {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.ProposalDetail_header__2E_RW {
    text-align: left;
}

.ProposalDetail_header__2E_RW h3 {
    text-align: left !important;
}

.ProposalDetail_body__1MJda p {
    text-align: left;
}

.ProposalDetail_author__2r7BN {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: left;
            justify-content: left;
    padding: 8px;
    padding-top: 0px;
  }

  .ProposalDetail_author__2r7BN h3:hover {
      text-align: left;
  }

  .ProposalDetail_featuredImage__1KPlG {
        width: 100%;
        height: auto;
  }

  .ProposalDetail_hrLine__2ATHK {
      padding-bottom: 20px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      height: 1px;
  }

  .ProposalDetail_user__27kbi {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    cursor: pointer;
    line-height: 14px;
    
}

.ProposalDetail_user__27kbi:hover {
    text-decoration: underline;
}


@media all and (max-width: 768px) {
    .ProposalDetail_layout__2ORL1 {
        width: 80%;
    }
}
.NewsDetails_layout__3Zj2T {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.NewsDetails_header__22xWe {
    text-align: center;
}

.NewsDetails_body__1MMHr p {
    text-align: left;
}

.NewsDetails_author__19oQ- {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 15px;
  }

  /* .author h3:hover {
      color: #0daa8b
  } */

  .NewsDetails_featuredImage__5xOBC {
        width: 100%;
        height: auto;
  }


@media all and (max-width: 768px) {
    .NewsDetails_layout__3Zj2T {
        width: 80%;
    }
}
.DocumentDetails_layout__15SKd {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.DocumentDetails_header__2ElSn {
    text-align: left;
}

.DocumentDetails_body__rWPVY p {
    text-align: left;
}

.DocumentDetails_author__1Ft-w {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: left;
            justify-content: left;
    padding: 8px;
    padding-top: 0px;
  }

  .DocumentDetails_author__1Ft-w h3:hover {
      text-align: left;
  }

  .DocumentDetails_featuredImage__1_Qjs {
        width: 100%;
        height: auto;
  }

  .DocumentDetails_hrLine__1uVel {
      padding-bottom: 20px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      height: 1px;
  }

  .DocumentDetails_user__3i87j {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    cursor: pointer;
    line-height: 14px;
    
}

.DocumentDetails_user__3i87j:hover {
    text-decoration: underline;
}

.DocumentDetails_button__1QFw9 {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.DocumentDetails_button__1QFw9:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}


@media all and (max-width: 768px) {
    .DocumentDetails_layout__15SKd {
        width: 80%;
    }
}
.ResearchDetails_layout__1I1KP {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.ResearchDetails_header__22GiI {
    text-align: center;
}

.ResearchDetails_body__b5Syw p {
    text-align: left;
}

.ResearchDetails_author__3Ihb4 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 15px;
  }

  /* .author h3:hover {
      color: #0daa8b
  } */

  .ResearchDetails_featuredImage__30hcv {
        width: 100%;
        height: auto;
  }


@media all and (max-width: 768px) {
    .ResearchDetails_layout__1I1KP {
        width: 80%;
    }
}
.ThesesDetails_layout__Z5WQY {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.ThesesDetails_header__3i2BG {
    text-align: left;
}

.ThesesDetails_body__2hI-E p {
    text-align: left;
}

.ThesesDetails_author__2MH-X {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: left;
            justify-content: left;
    padding: 8px;
    padding-top: 0px;
  }

  .ThesesDetails_author__2MH-X h3:hover {
      text-align: left;
  }

  .ThesesDetails_featuredImage__BZXzj {
        width: 100%;
        height: auto;
  }

  .ThesesDetails_hrLine__11duC {
      padding-bottom: 20px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      height: 1px;
  }

  .ThesesDetails_user__2bqpp {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    cursor: pointer;
    line-height: 14px;
    
}

.ThesesDetails_user__2bqpp:hover {
    text-decoration: underline;
}

.ThesesDetails_button__2mPTV {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.ThesesDetails_button__2mPTV:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}


@media all and (max-width: 768px) {
    .ThesesDetails_layout__Z5WQY {
        width: 80%;
    }
}
.Publications_layout__2Z2GS {
  
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.Publications_coverImg__1z_oA {
    width: 100%;
    padding-top: 50px;
}

.Publications_header__2maTQ {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.Publications_topic__1RvBp {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Publications_number__1Jqi4 {
    margin-top: -70px;
    width: 120px;
    height: 120px;
    background-color: #3E6947;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
    
}
.Publications_topic__1RvBp h3 {
    line-height: 40px;
    font-size: 50px;
    color: white;
}

.Publications_topic__1RvBp p {
    padding-top: 10px;
    line-height: 16px;
    font-size: 13px;
    color: white;
}

.Publications_header__2maTQ input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.Publications_header__2maTQ input:focus {
    border: solid 2px #3e6947;
}

.Publications_searchBar__3v3e2 {
    display: -webkit-flex;
    display: flex;
}

.Publications_searchBar__3v3e2 button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.Publications_searchBar__3v3e2 button:hover {
    background-color: #33583b;
}

.Publications_cardContainer__3DBvz {
    width: 65%;
    margin: 0px auto;
}


.Publications_pagination__1Tk1C {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.Publications_pageNum__2nMNn {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.Publications_bg__3QhHF {
    background-color: #3e6947;
}

.Publications_pageNum__2nMNn:focus {
    background-color: #3e6947;
    color: white;
}


@media all and (max-width: 768px) {
    .Publications_featuresContainer__3z05S {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .Publications_paragraph__2UgPs {
      width: 100%;
    }

    .Publications_header__2maTQ {
      width: 95%;

  }

  .Publications_cardContainer__3DBvz {
    width: 98%;
}

.Publications_searchBar__3v3e2 button {
  width: 30%;
}

  
  }

.Card_card__2GukZ {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    padding: 30px 40px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
}

.Card_title__9peP8 {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.Card_title__9peP8:hover {
    color: #008066;
}

.Card_card__2GukZ p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__mjLz7 {
    color: gray;
    font-size: 13px;
    line-height: 18px;
}

.Card_user__bnKJJ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    line-height: 14px;
    word-wrap: break-word;  
    
}

.Card_user__bnKJJ:hover {
    text-decoration: underline;
}

@media all and (max-width: 768px) {
    

  
  }
.Spinner_loader__ZZPqW {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3e6947;
  width: 30px;
  height: 30px;
  -webkit-animation: Spinner_spin__2wGKJ 2s linear infinite; /* Safari */
  animation: Spinner_spin__2wGKJ 2s linear infinite;
  
}

/* Safari */
@-webkit-keyframes Spinner_spin__2wGKJ {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__2wGKJ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.Spinner_container__1exbF {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Proposals_layout__2KW7Q {
  
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.Proposals_coverImg__1E_G4 {
    width: 100%;
    padding-top: 50px;
}

.Proposals_header__1ivp8 {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.Proposals_summary__8wc48 {
    text-align: center !important;
}

.Proposals_topic__3v5vP {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Proposals_number__Zo537 {
    margin-top: -70px;
    width: 120px;
    height: 120px;
    background-color: #3E6947;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
    
}
.Proposals_topic__3v5vP h3 {
    line-height: 40px;
    font-size: 50px;
    color: white;
}

.Proposals_topic__3v5vP p {
    padding-top: 10px;
    line-height: 16px;
    font-size: 13px;
    color: white;
}

.Proposals_header__1ivp8 input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.Proposals_header__1ivp8 input:focus {
    border: solid 2px #3e6947;
}

.Proposals_searchBar__28u5I {
    display: -webkit-flex;
    display: flex;
}

.Proposals_searchBar__28u5I button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.Proposals_searchBar__28u5I button:hover {
    background-color: #33583b;
}

.Proposals_cardContainer__3XHOA {
    width: 65%;
    margin: 0px auto;
}


.Proposals_pagination__UIBq8 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.Proposals_pageNum__2-jux {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.Proposals_bg__1yYaE {
    background-color: #3e6947;
}

.Proposals_pageNum__2-jux:focus {
    background-color: #3e6947;
    color: white;
}


@media all and (max-width: 768px) {
    .Proposals_featuresContainer__3AxKN {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .Proposals_paragraph__OzLld {
      width: 100%;
    }

    .Proposals_header__1ivp8 {
      width: 95%;

  }

  .Proposals_cardContainer__3XHOA {
    width: 98%;
}

.Proposals_searchBar__28u5I button {
  width: 30%;
}

  
  }

.Card_card__3VV5u {
    text-align: left;
    border: solid rgb(226, 226, 226) 1px;
    padding: 10px 30px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
}

.Card_title__jtcIG {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.Card_title__jtcIG:hover {
    color: #008066;
}

.Card_card__3VV5u p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__3eirO {
    color: gray;
    font-size: 13px;
    line-height: 18px;
}

.Card_user__VxfxB {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    line-height: 14px;
    word-wrap: break-word;  
    
}

.Card_user__VxfxB:hover {
    text-decoration: underline;
}

@media all and (max-width: 768px) {
    

  
  }
.Documents_layout__3ucXG {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.Documents_header__3zjDF {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.Documents_header__3zjDF input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.Documents_header__3zjDF input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.Documents_searchBar__Gg6LI {
    display: -webkit-flex;
    display: flex;
}

.Documents_searchBar__Gg6LI button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.Documents_searchBar__Gg6LI button:hover {
    background-color: #33583b;
}

.Documents_cardContainer__B2xi0 {
    width: 65%;
    margin: 0px auto;
}


.Documents_pagination__3HVdH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.Documents_pageNum__23wgi {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.Documents_bg__17o2K {
    background-color: #3e6947;
}

.Documents_pageNum__23wgi:focus {
    background-color: #3e6947;
    color: white;
}



.Documents_button__2fWLN {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.Documents_button__2fWLN:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}

@media all and (max-width: 768px) {
    .Documents_featuresContainer__1C45Y {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .Documents_paragraph__x3Npy {
      width: 100%;
    }

    .Documents_header__3zjDF {
      width: 95%;

  }

  .Documents_cardContainer__B2xi0 {
    width: 98%;
}

.Documents_searchBar__Gg6LI button {
    width: 30%;
}

  
  }

.Card_card__shGnH {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    padding: 30px 40px;
    border-radius: 10px;
    margin: 10px auto;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    width: 70%;
}

.Card_title__3Va6- {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.Card_title__3Va6-:hover {
    color: #3e6947;
}

.Card_card__shGnH p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__W02mv {
    color: gray;
    font-size: 13px;
}

.Card_user__nMhvC {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    line-height: 14px;
    word-wrap: break-word;  
    
}

.Card_user__nMhvC:hover {
    text-decoration: underline;
}


.Card_toolbar__2od4E {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:space-between;
            justify-content:space-between;
}

.Card_button__2t1dK {
    padding: 9px 13px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    font-size: 12px;
    background-color: white;
    

}

.Card_button__2t1dK:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}


@media all and (max-width: 768px) {
    
    .Card_card__shGnH {
        width: 100%;
    }

  
  }
.News_featuresContainer__12rDt {
    margin: 0px auto;
    width: 90%;
    padding-top: 130px;
   
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .News_autoGrid__kaBq5 {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .News_items__1C-T3 {
    width: 100%;
  }
  
  .News_paragraph__V5-eX {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .News_listItem__2dBvx {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  .News_listItem__2dBvx h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .News_listItem__2dBvx h2:hover {
    color: #50835a;
  }
  
  .News_listItem__2dBvx p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .News_listItem__2dBvx:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
 
  
  
  
  .News_imgThumbnail__2ONJR {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .News_imgThumbnail__2ONJR:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  .News_authorAndDate__n61mq {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0px 15px;
  }
  
  .News_authorAndDate__n61mq h3 {
    color: #0d9b7f;
    font-size: 10px;
  }

  .News_author__1BQeC {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
  }

  .News_pagination__bgUyF {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.News_pageNum__cG6wx {
  margin: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;
}
.News_bg__1WQF8 {
    background-color: #3E6947;
}

.News_pageNum__cG6wx:focus {
    background-color: #3E6947;
    color: white;
}

  
  @media all and (max-width: 768px) {
    .News_featuresContainer__12rDt {
      width: 90%;
      display: block;
    }
  
    .News_items__1C-T3 {
      width: 100%;
    }
  
    .News_paragraph__V5-eX {
      width: 100%;
     
    }
  
    .News_imgThumbnail__2ONJR {
      height: 50vh;
    }
  }
  
/* .featuresContainer {
    margin: 0px auto;
    width: 100%;
    padding: 0px 60px 20px 60px;
  
   
  } */
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .Thumbnail_autoGrid__1vfcX {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Thumbnail_items__148iR {
    width: 100%;
  }
  
  
  .Thumbnail_listItem__fC3Ln {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left !important;
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
    border-radius: 8px;
  }
  
  .Thumbnail_listItem__fC3Ln h2 {
    text-align: center;
    padding: 15px 25px 0px 25px;
    text-align: center;
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }
  
  
  .Thumbnail_listItem__fC3Ln p {
    text-align: center;
    padding: 0px 25px 10px 25px;
    text-align: center;
    cursor: pointer;
  }
  
  .Thumbnail_designation__2V2DT {
    font-size: 14px;
    line-height: 17px;
    color: rgb(73, 73, 73);
  }

  /* .listItem:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  } */
  

  
  .Thumbnail_imgThumbnail__3_g2g {
    margin: 0px auto;
    border-radius: 100%;
    object-fit: cover;
    width: 30vh;
    height: 30vh;
  }
  .Thumbnail_imgThumbnail__3_g2g:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }


.Thumbnail_imgContainer__1fFVQ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
  

  @media all and (max-width: 768px) {
  
    .Thumbnail_items__148iR {
      width: 100%;
    }

    .Thumbnail_imgThumbnail__3_g2g {
      width: 40vh;
      height: 40vh;
    }
  
   
  }
  
.Team_featuresContainer__wvyK_ {
    margin: 0px auto;
    width: 100%;
    padding: 120px 60px 20px 60px;
  
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .Team_autoGrid__1HESH {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Team_items__3_dTT {
    width: 100%;
  }
  
  .Team_paragraph__meVLq {
    width: 70%;
    padding-bottom: 40px;
    text-align: center;
    margin: 0px auto;
  }
  
  .Team_listItem__3xxgr {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  .Team_listItem__3xxgr h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .Team_listItem__3xxgr h2:hover {
    color: #1abc9c;
  }
  
  .Team_listItem__3xxgr p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .Team_listItem__3xxgr:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  


  
  .Team_imgThumbnail__1CSwQ {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .Team_imgThumbnail__1CSwQ:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }


  

  
  
  @media all and (max-width: 768px) {
    .Team_featuresContainer__wvyK_ {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
  
    .Team_items__3_dTT {
      width: 100%;
    }
  
    .Team_paragraph__meVLq {
      width: 100%;
    }
  
    .Team_imgThumbnail__1CSwQ {
      height: 50vh;
    }
  }
  

.UserCard_cardLayout__MPjKk {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-right: 8px;
    text-align: center;
  
}
.UserCard_cardLayout__MPjKk p {
    line-height: 45px;
    text-align: center;
}
.UserCard_userCard__ox42w {
   position: fixed;
    width: 350px;
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px;
    margin-top:68px;
    border-radius: 10px;
    background-color: white;
    z-index: 1000;
    text-align: center;
    
}

.UserCard_userMenu__27sZC {
    padding: 50px;
}

.UserCard_avater__2_1bQ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-bottom: 10px;
}

.UserCard_profileButton__3sRDg {
    padding: 15px 25px;
    border: solid gray 1px;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 10px;
}

.UserCard_line__1RYJf {
    border-top: 1px solid rgb(228, 228, 228);
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.UserCard_cardContent__8dI3R {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}


/* .content {
  display: flex;
  justify-content: flex-start;
  padding-right: 8px;
  text-align: center;
  background-color: white;
  z-index: 2000;
} */

.Leventis_content__3odiO img {
  position: fixed;
  top: 0;
  width: 50px;
  height: 50px;
  background-color: white;
  /* box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgb(170, 170, 170);
  border-right: 1px solid rgb(170, 170, 170);
  border-bottom: 1px solid rgb(170, 170, 170);
  border-radius: 0px 0px 2px 2px; */
  /* padding: 3px; */
}

/* .overlay h1 {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 1px;
  font-weight: 700px;
  margin: 0 0 5px;
  color: white;
  text-align: left;
  width: 70%;
}

.overlay p {
  margin: 0;
    color: white;
    text-align: left;
} */

.Alumni_featuresContainer__2sjqY {
    margin: 0px auto;
    width: 100%;
    padding: 120px 60px 20px 60px;
  
   
  }
  
  
  .Alumni_layout__1tjDy {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.Alumni_header__2dPPk {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.Alumni_header__2dPPk h1 {
  padding: 20px 0px;
}

.Alumni_header__2dPPk h1, p {
  text-align: center;
}


.Alumni_header__2dPPk input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.Alumni_header__2dPPk input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.Alumni_searchBar__2n8M7 {
    display: -webkit-flex;
    display: flex;
}

.Alumni_searchBar__2n8M7 button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background-color: #3e6947;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.Alumni_searchBar__2n8M7 button:hover {
    background-color: #2f5036
}

.Alumni_thumbnails__ZF1iw {
    width: 90%;
    margin: 0px auto;
}


.Alumni_pagination__Q_dJ1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.Alumni_pageNum__1LW9Z {
  margin: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;
}

.Alumni_bg__83D9d {
    background-color: #3e6947;
}

.Alumni_pageNum__1LW9Z:focus {
    background-color: #3e6947;
    color: white;
}

  .Alumni_paragraph__19ETh {
    width: 70%;
    padding-bottom: 40px;
    text-align: center;
    margin: 0px auto;
  }
  
  
  

  
  
  @media all and (max-width: 768px) {
    .Alumni_featuresContainer__2sjqY {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .Alumni_paragraph__19ETh {
      width: 100%;
    }
    
    .Alumni_header__2dPPk {
      text-align: center;
      width: 90%;
      margin: 0 auto;
      padding-bottom: 30px;
  }

  .Alumni_searchBar__2n8M7 button {
    width: 30%;
  }

  }
  
/* .featuresContainer {
    margin: 0px auto;
    width: 100%;
    padding: 0px 60px 20px 60px;
  
   
  } */
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .Grid_autoGrid__2kZe9 {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 0.5rem;
  }
  
  .Grid_items__3q6YQ {
    width: 100%;
    padding-bottom: 30px;
  }
  
  
  .Grid_listItem__3afkl {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 0rem 1rem;
    text-align: left;
    list-style-type: none;
    text-align: left !important;
    border-bottom: 1px solid rgb(184, 184, 184);
    border-radius: 4px;
    background-color: white;
  }

  .Grid_text__vL31N p {
    color: gray;
    font-size: gray;
    text-align: left;
    font-size: 13px;
    line-height: 15px;
    
  }

  .Grid_text__vL31N h2 {
    text-align: left;
  }
  
  .Grid_listItem__3afkl h2 {
    text-align: left;
    padding: 15px 25px 0px 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
    cursor: pointer;
  }
  
  
  .Grid_listItem__3afkl p {
    text-align: left;
    padding: 0px 25px 10px 25px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    cursor: pointer;
    color: rgb(170, 170, 170);
  }
  
  .Grid_listItem__3afkl:hover {
    background-color: #3e6947;
    color: white;
   
  }
  
  .Grid_listItem__3afkl:hover h2 {
    color: white;
   
  }
  

  
  .Grid_imgThumbnail__1teSA {
    margin: 0px auto;
    border-radius: 100%;
    object-fit: cover;
    width: 25px;
    height: 25px;
  }
  .Grid_imgThumbnail__1teSA:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }


.Grid_imgContainer__1RBYP {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
  

  @media all and (max-width: 768px) {
  
    .Grid_items__3q6YQ {
      width: 100%;
    }

    .Grid_imgThumbnail__1teSA {
      width: 20px;
      height: 20px;
    }
  
   
  }
  
.People_featuresContainer__icvem {
    margin: 0px auto;
    width: 100%;
    padding: 120px 60px 20px 60px;
  
   
  }
  
  
  .People_layout__1GTcj {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.People_header__3FPRQ {
    text-align: center;
    width: 55%;
    margin: 30px auto;
    padding-bottom: 30px;
}

.People_header__3FPRQ input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.People_header__3FPRQ input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.People_searchBar__3Z5ZI {
    display: -webkit-flex;
    display: flex;
}

.People_searchBar__3Z5ZI button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #1ABC9C 1px;
    cursor: pointer;
    width: 20%;
    background-color: #1ABC9C;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.People_searchBar__3Z5ZI button:hover {
    background-color: #099b7e;
}

.People_thumbnails__3NdRR {
    width: 90%;
    margin: 0px auto;
}


.People_pagination__3KqU1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.People_pageNum__1yBIW {
    margin: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.People_bg__32tMj {
    background-color: #1ABC9C;
}

.People_pageNum__1yBIW:focus {
    background-color: #1ABC9C;
    color: white;
}

  .People_paragraph__2grmS {
    width: 70%;
    padding-bottom: 40px;
    text-align: center;
    margin: 0px auto;
  }
  
  
  

  
  
  @media all and (max-width: 768px) {
    .People_featuresContainer__icvem {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .People_paragraph__2grmS {
      width: 100%;
    }

    .People_header__3FPRQ {
      width: 90%;

  }
  
  }
  
.Research_featuresContainer__ZLHnP {
    margin: 0px auto;
    width: 90%;
    padding-top: 130px;
   
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .Research_autoGrid__cgk8w {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Research_items__2gjk2 {
    width: 100%;
  }
  
  .Research_paragraph__20Hmj {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .Research_listItem__3Nf9s {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  .Research_listItem__3Nf9s h2 {
    text-align: center;
    padding: 5px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
  }
  
  .Research_listItem__3Nf9s h2:hover {
    color: #1abc9c;
  }
  
  .Research_listItem__3Nf9s p {
    text-align: center;
    padding: 10px 15px;
    text-align: left;
  }
  
  .Research_listItem__3Nf9s:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .Research_imgThumbnail__CX7RJ {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }

  


  .Research_pagination__3TWWX {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.Research_pageNum__7lUjr {
    margin: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.Research_bg__27dRj {
    background-color: #1ABC9C;
}

.Research_pageNum__7lUjr:focus {
    background-color: #1ABC9C;
    color: white;
}

  
  @media all and (max-width: 768px) {
    .Research_featuresContainer__ZLHnP {
      width: 90%;
      display: block;
    }
  
    .Research_items__2gjk2 {
      width: 100%;
    }
  
    .Research_paragraph__20Hmj {
      width: 100%;
     
    }
  
    .Research_imgThumbnail__CX7RJ {
      height: 50vh;
    }
  }








  /* @import url(https://fonts.googleapis.com/css?family=Raleway); */

*, *:before, *:after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body{
  background: #f9f9f9;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
} */

.Research_mainTitle__1E_9Q{
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  /* padding: 0.7em 0; */
}

.Research_container__tLUf_{
  float: left;
  width: 100%;
}
@media screen and (max-width: 640px){
  .Research_container__tLUf_{
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 900px){
  .Research_container__tLUf_{
    width: 100%
  }
}

.Research_container__tLUf_ .Research_title__1n6dA{
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.Research_content__2SFXD {
  position: relative;
  width: 100%;
  /* max-width: 400px; */
  margin: auto;
  overflow: hidden;
}

.Research_content__2SFXD .Research_contentOverlay__1qA9i {
  background: rgba(0,0,0,0.7);
  position: absolute;
  height: 97%;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  /* left: 0;
  top: 0;
  bottom: 0;
  right: 0; */
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
}

.Research_content__2SFXD:hover .Research_contentOverlay__1qA9i{
  opacity: 1;
}

.Research_contentImage__PeY_Y{
  width: 100%;
}

.Research_contentDetails__1VsJ5 {
  position: absolute;
  text-align: center;
  padding-left: 1em; 
  padding-right: 1em;
  width: 100%;
  margin: 0px auto;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out 0s;
}

.Research_content__2SFXD:hover .Research_contentDetails__1VsJ5{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.Research_contentDetails__1VsJ5 h3{
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.Research_contentDetails__1VsJ5 p{
  color: #fff;
  font-size: 0.8em;
}

.Research_fadeInBottom__2-HKZ{
  top: 80%;
}

.Research_fadeInTop__2ZTO9{
  top: 20%;
}

.Research_fadeInLeft__2sDvD{
  left: 20%;
}

.Research_fadeInRight__Gu7Wz{
  left: 80%;
}
  
.Theses_layout__nvPcb {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.Theses_header__3g3DU {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.Theses_header__3g3DU input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.Theses_header__3g3DU input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.Theses_searchBar__39ecb {
    display: -webkit-flex;
    display: flex;
}

.Theses_searchBar__39ecb button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.Theses_searchBar__39ecb button:hover {
    background-color: #33583b;
}

.Theses_cardContainer__1GDdZ {
    width: 65%;
    margin: 0px auto;
}


.Theses_pagination__2ThKR {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.Theses_pageNum__FE5Ub {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.Theses_bg__14NLe {
    background-color: #3e6947;
}

.Theses_pageNum__FE5Ub:focus {
    background-color: #3e6947;
    color: white;
}



.Theses_button__R9iYz {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.Theses_button__R9iYz:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}

@media all and (max-width: 768px) {
    .Theses_featuresContainer__3RwUv {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .Theses_paragraph__3OQpo {
      width: 100%;
    }

    .Theses_header__3g3DU {
      width: 95%;

  }

  .Theses_cardContainer__1GDdZ {
    width: 98%;
}

.Theses_searchBar__39ecb button {
    width: 30%;
}
  
  }

.Card_card__fCE8U {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    padding: 30px 40px;
    border-radius: 10px;
    margin: 10px auto;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    width: 80%;
}

.Card_title__1ATmK {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.Card_title__1ATmK:hover {
    color: #3e6947;
}

.Card_card__fCE8U p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__ATOt7 {
    color: gray;
    font-size: 13px;
}

.Card_user__2YseH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    line-height: 14px;
    word-wrap: break-word;  
    
}

.Card_user__2YseH:hover {
    text-decoration: underline;
}


.Card_toolbar__1kJPi {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Card_button__14rTI {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    font-size: 14px;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.Card_button__14rTI:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}

@media all and (max-width: 768px) {
    
    .Card_card__fCE8U {
        width: 100%;
    }

  
  }
.StaticPage_layout__3uy0m {
    margin: 0px auto;
    width: 100%;
    padding-bottom: 80px;
    
}

.StaticPage_header__2hn1e {
    text-align: center;
}

.StaticPage_body__pO8AN {
    width: 70%;
    margin: 0px auto;
    text-align: left;
    line-height: 30px !important;
}

.StaticPage_body__pO8AN p {
    text-align: left;
}

.StaticPage_author__bKSk8 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 15px;
  }

  /* .author h3:hover {
      color: #0daa8b
  } */

  .StaticPage_featuredImage__-gh4r {
        width: 100%;
        height: 80vh;
        object-fit: cover;
  }


@media all and (max-width: 768px) {
    .StaticPage_layout__3uy0m {
        width: 100%;
    }
    
.StaticPage_body__pO8AN {
    width: 90%;
    line-height: 30px;
}



}
.Password_button__deM6W {
    padding: 9px 13px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    font-size: 12px;
    background-color: white;
    margin: 8px;
    

}

.Password_button__deM6W:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}

.Password_buttons__2EXmV {
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.Moderator_layout__1O7-4 {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.Moderator_header__1O79F {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.Moderator_header__1O79F input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.Moderator_header__1O79F input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.Moderator_searchBar__dPo2l {
    display: -webkit-flex;
    display: flex;
}

.Moderator_searchBar__dPo2l button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.Moderator_searchBar__dPo2l button:hover {
    background-color: #33583b;
}

.Moderator_cardContainer__3EOdC {
    width: 65%;
    margin: 0px auto;
}


.Moderator_pagination__2QATW {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.Moderator_pageNum__2P-iM {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.Moderator_bg__2vUeD {
    background-color: #3e6947;
}

.Moderator_pageNum__2P-iM:focus {
    background-color: #3e6947;
    color: white;
}


.Moderator_editButton__35aWO {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid gray;
    border-radius: 50px;
    padding: 15px 30px 15px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    margin: 10px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
  }

  .Moderator_btnContainer__1Ui6b {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
  }


@media all and (max-width: 768px) {
    .Moderator_featuresContainer__gCNLJ {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .Moderator_paragraph__2urSR {
      width: 100%;
    }

    .Moderator_header__1O79F {
      width: 95%;

  }

  .Moderator_cardContainer__3EOdC {
    width: 98%;
}

.Moderator_searchBar__dPo2l button {
    width: 30%;
}
  
  }

.Card_card__23Ewa {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    padding: 10px 40px;
    padding-bottom: 5px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    
}

.Card_title__3vzIT {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    
}


.Card_title__3vzIT:hover {
    color: #008066;
}

.Card_card__23Ewa p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.Card_year__1qkH0 {
    color: gray;
    font-size: 13px;
}

.Card_user__2_etL {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    line-height: 14px;
    word-wrap: break-word;  
    
}

.Card_user__2_etL:hover {
    text-decoration: underline;
}

.Card_toolBox__1oM6H {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:flex-end;
            justify-content:flex-end;
    width: 100%;
    border-top: solid 1px rgb(230, 230, 230);
    padding-top: 3px;
    margin-top: 3px;
}

.Card_toolBox__1oM6H div {
    margin: 5px;
}

@media all and (max-width: 768px) {
    

  
  }
.Events_layout__2p-uK {
    padding: 100px 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.ManageProfiles_featuresContainer__2MT7O {
    margin: 0px auto;
    width: 100%;
    padding: 120px 60px 20px 60px;
  
   
  }
  
  
  .ManageProfiles_layout__2v_Tk {
    padding-top: 160px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.ManageProfiles_header__1K6Ga {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.ManageProfiles_header__1K6Ga h1 {
  padding: 20px 0px;
}

.ManageProfiles_header__1K6Ga h1, p {
  text-align: center;
}


.ManageProfiles_header__1K6Ga input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.ManageProfiles_header__1K6Ga input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.ManageProfiles_searchBar__16KI6 {
    display: -webkit-flex;
    display: flex;
}

.ManageProfiles_searchBar__16KI6 button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background-color: #3e6947;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.ManageProfiles_searchBar__16KI6 button:hover {
    background-color: #2f5036
}

.ManageProfiles_thumbnails__1QTds {
    width: 90%;
    margin: 0px auto;
}


.ManageProfiles_pagination__t_e6a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.ManageProfiles_pageNum__25ICV {
  margin: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;
}

.ManageProfiles_bg__1dvHX {
    background-color: #3e6947;
}

.ManageProfiles_pageNum__25ICV:focus {
    background-color: #3e6947;
    color: white;
}

  .ManageProfiles_paragraph__167Ws {
    width: 70%;
    padding-bottom: 40px;
    text-align: center;
    margin: 0px auto;
  }
  
  
  

  
  
  @media all and (max-width: 768px) {
    .ManageProfiles_featuresContainer__2MT7O {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .ManageProfiles_paragraph__167Ws {
      width: 100%;
    }
    
    .ManageProfiles_header__1K6Ga {
      text-align: center;
      width: 90%;
      margin: 0 auto;
      padding-bottom: 30px;
  }

  .ManageProfiles_searchBar__16KI6 button {
    width: 30%;
  }

  }
  
  .ManageProfiles_addNew__3F-0J {
    /* border: 1px solid #3e6947;  */
    border: none;
    padding: 18px 30px;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)

  }


  .ManageProfiles_addNew__3F-0J:hover {
    background-color: rgb(235, 235, 235);
  }

/* .featuresContainer {
    margin: 0px auto;
    width: 100%;
    padding: 0px 60px 20px 60px;
  
   
  } */
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .Grid_autoGrid__35WTR {
    --auto-grid-min-size: 18rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 0.5rem;
  }
  
  .Grid_items__gGjAK {
    width: 100%;
    padding-bottom: 30px;
  }
  
  
  .Grid_listItem__26IEt {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0rem 1rem;
    text-align: left;
    list-style-type: none;
    text-align: left !important;
    border-bottom: 1px solid rgb(184, 184, 184);
    border-radius: 4px;
    background-color: white;
  }

  .Grid_text__3-8uw p {
    color: gray;
    font-size: gray;
    text-align: left;
    font-size: 13px;
    line-height: 15px;
  }

  .Grid_text__3-8uw h2 {
    text-align: left;
  }
  
  .Grid_listItem__26IEt h2 {
    text-align: left;
    padding: 15px 25px 0px 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
    cursor: pointer;
  }
  
  
  .Grid_listItem__26IEt p {
    text-align: left;
    padding: 0px 25px 10px 25px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    cursor: pointer;
    color: rgb(170, 170, 170);
  }
  
  .Grid_listItem__26IEt:hover {
    background-color: #3e6947;
    color: white;
   
  }
  
  .Grid_listItem__26IEt:hover h2 {
    color: white;
   
  }
  

  
  .Grid_imgThumbnail__1JEja {
    margin: 0px auto;
    border-radius: 100%;
    object-fit: cover;
    width: 25px;
    height: 25px;
  }
  .Grid_imgThumbnail__1JEja:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }


.Grid_imgContainer__2fttu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
  

  @media all and (max-width: 768px) {
  
    .Grid_items__gGjAK {
      width: 100%;
    }

    .Grid_imgThumbnail__1JEja {
      width: 20px;
      height: 20px;
    }
  
   
  }
  

.ProfileImage_profilePicture__3PFYF {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
  
.ProfileImage_fileUpload__39G6e label {
  display: -webkit-flex;
  display: flex;
  background-color: rgb(235, 235, 235);
  color: rgb(65, 65, 65);
  border: 1px gray dashed;
  padding: 0.8rem 0rem 0rem 0.9rem;
  font-family: sans-serif;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  width: 150px;
  height: 150px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
  
  .ProfileImage_fileUpload__39G6e label:hover {
    background-color: rgb(228, 228, 228);
  }
  
  .ProfileImage_upload__1FpD7 {
    display: -webkit-flex;
    display: flex;
    border: solid 1px rgb(175, 175, 175);
    width: 90%;
    margin: 20px;
    border-radius: 0.7rem;
  }


  .ProfileImage_uploads__1fHiu {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  
  .ProfileImage_upload__1FpD7 p {
    width: 80%;
    padding-left: 15px;
  }
  
  .ProfileImage_profilePic__26ICB {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 6px rgb(241, 241, 241) solid;
    
  }

  .ProfileImage_profilePicContainer__2WEFk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
.SpeciesBoard_layout__3Q2zM {
    padding-top: 120px;
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.SpeciesBoard_header__11qOl {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.SpeciesBoard_header__11qOl input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}


.SpeciesBoard_newBtn__1tsy9 {
  padding: 15px 20px;
  border: solid 2px #3e6947;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;

}

.SpeciesBoard_newBtn__1tsy9:hover {
  background-color: #3e6947;
  color: white;
}

.SpeciesBoard_header__11qOl input:focus {
    border: solid 1px rgb(0, 148, 79);
}

.SpeciesBoard_searchBar__1lsKN {
    display: -webkit-flex;
    display: flex;
}

.SpeciesBoard_searchBar__1lsKN button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.SpeciesBoard_searchBar__1lsKN button:hover {
    background-color: #33583b;
}

.SpeciesBoard_cardContainer__1XFHL {
    width: 90%;
    margin: 0px auto;
}


.SpeciesBoard_pagination__2uaFW {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.SpeciesBoard_pageNum__QG1oE {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.SpeciesBoard_bg__19HhF {
    background-color: #3e6947;
}

.SpeciesBoard_pageNum__QG1oE:focus {
    background-color: #3e6947;
    color: white;
}

.SpeciesBoard_editButton__1vQSM {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid gray;
    border-radius: 50px;
    padding: 15px 30px 15px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    margin: 10px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
  }

  .SpeciesBoard_btnContainer__38dPi {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
  }


@media all and (max-width: 768px) {
    .SpeciesBoard_featuresContainer__2jtr9 {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .SpeciesBoard_paragraph__CcfvO {
      width: 100%;
    }

    .SpeciesBoard_header__11qOl {
      width: 95%;

  }

  .SpeciesBoard_cardContainer__1XFHL {
    width: 98%;
}

.SpeciesBoard_searchBar__1lsKN button {
    width: 30%;
}
  
  }






  /* .featuresContainer {
    margin: 0px auto;
    width: 100%;
    padding: 0px 60px 20px 60px;
  
   
  } */
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
    .SpeciesBoard_autoGrid__LRGa_ {
        --auto-grid-min-size: 18rem;
      
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0.5rem;
      }
      
      .SpeciesBoard_items__2oj70 {
        width: 100%;
        padding-bottom: 30px;
      }
      
      
      .SpeciesBoard_listItem__1oXoV {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 0rem 1rem;
        text-align: left;
        list-style-type: none;
        text-align: left !important;
        border-bottom: 1px solid rgb(184, 184, 184);
        border-radius: 4px;
        background-color: white;
      }
    
      .SpeciesBoard_text__whl1O p {
        color: gray;
        font-size: gray;
        text-align: left;
        font-size: 13px;
        line-height: 15px;
      }
    
      .SpeciesBoard_text__whl1O h2 {
        text-align: left;
      }
      
      .SpeciesBoard_listItem__1oXoV h2 {
        text-align: left;
        padding: 15px 25px 0px 25px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        text-align: left;
        font-size: 14px;
        line-height: 23px;
        cursor: pointer;
      }
      
      
      .SpeciesBoard_listItem__1oXoV p {
        text-align: left;
        padding: 0px 25px 10px 25px;
        font-family: 'Roboto', sans-serif;
        text-align: left;
        cursor: pointer;
        color: rgb(170, 170, 170);
      }
      
      .SpeciesBoard_listItem__1oXoV:hover {
        background-color: #3e6947;
        color: white;
       
      }
      
      .SpeciesBoard_listItem__1oXoV:hover h2 {
        color: white;
       
      }
      
    
      
      .SpeciesBoard_imgThumbnail__1t7RW {
        margin: 0px auto;
        border-radius: 100%;
        object-fit: cover;
        width: 25px;
        height: 25px;
      }
      .SpeciesBoard_imgThumbnail__1t7RW:hover {
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        cursor: pointer;
      }
    
    
    .SpeciesBoard_imgContainer__1QWh4 {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
    }
      
    
      @media all and (max-width: 768px) {
      
        .SpeciesBoard_items__2oj70 {
          width: 100%;
        }
    
        .SpeciesBoard_imgThumbnail__1t7RW {
          width: 20px;
          height: 20px;
        }
      
       
      }
      
.Features_featuresContainer__vwAE_ {
  margin: 30px auto;
  margin-top: 80px;
  width: 70%;
  display: -webkit-flex;
  display: flex;
  text-align: center !important;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Features_paragraph__hQcIw p {
  text-align: center !important;
}

.Features_hrLine__27A1A {
  margin: 30px;
}

@media all and (max-width: 768px) {
  .Features_featuresContainer__vwAE_ {
    margin: 100px auto;
    width: 95%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Features_items__Wokmt {
    width: 100%;
  }

  .Features_paragraph__hQcIw {
    width: 100%;
    padding: 0px 10px;
    padding-bottom: 50px;
  }


  .Features_imgThumbnail__2ZvUe {
    height: 30vh;
  }
}

.Hero_bigImage__jQkyu {
  height: 95vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  /* background-image: url("https://i.postimg.cc/YS4YNCp2/Amurum-2.jpg"); */
}

.Hero_overlay__1vuuc {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(12, 31, 21, 0.678);
  /* background: rgba(0, 0, 0, 0.65); */
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}


.Hero_title__jxJj6 {
  font-size: 120px !important;
  line-height: 100px !important;
  width: 100%;
  font-weight: 700;

}

.Hero_content__2FUQy {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

   
}

/* .content img {
    height: 130px;
    width: 130px;
    margin-right: 40px;
} */

.Hero_overlay__1vuuc h1 {
  font-size: 50px;
  line-height: 45px;
  letter-spacing: 0px;
  font-weight: 700px;
  color: white;
  text-align: left;
  width: 70%;
  /* font-family: Roboto; */
  /* font-weight: 100px !important; */
}


.Hero_overlay__1vuuc p {
  margin: 0;
    color: white;
    text-align: left;
}

.Hero_buttonContainer__3uxJq {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 10px;
  margin-top: 20px;
}

.Hero_button__2PFsa {
  padding: 15px 20px;
  border-radius: 50px;
  border: solid 2px #ffffff;
  cursor: pointer;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
  color: white;

}

.Hero_button__2PFsa:hover {
  background-color: #3E6947;
  color: white;
  border: solid 1px #3E6947;
}


.Hero_birdInfo__3aFO9 {
  display: -webkit-flex;
  display: flex;
  font-family: Roboto;
  box-shadow: 1px 6px 15px rgba(45, 55, 71, 0.285457), 
  -6px -19px 21px rgba(244, 248, 251, 0.345662), 
  -5px -5px 14px rgba(255, 255, 255, 0.0155704);
  height: 95vh;
  
}


.Hero_imgContainer__1wZHZ {
  width: 50%;
  height: 95vh;
  background-color: #1C2E20;
}

.Hero_birdImg__3hkjo {
  object-fit: cover;
  height: 100% !important;
}

.Hero_birdStat__YGsgh {
  width: 50%;
  padding: 100px;
  background: #1C2E20;
}

.Hero_readmore__2dz-F {
  margin-top: 10px;
  padding: 15px;
  border: solid 2px green;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.Hero_readmore__2dz-F:hover {
  background-color: green;
}
/* .birdImg, .birdStat {
  flex-basis: 100%;
  text-align: center;
} */

.Hero_comName__2sk7D {
  margin-top: 10px;
  font-size: 55px;
  line-height: 50px;
  color: #fdfffb;
}


.Hero_sciName__2Sont {
  font-size: 22px;
  font-style: italic;
  margin: 20px 0px;
  color: #ffffff;
}


.Hero_identification__1PEI3 p  {
  text-align: center !important;
}

.Hero_identification__1PEI3 span {
  background-color: transparent !important; 
  color: white !important;
  font-size: 14px;
}

.Hero_identification__1PEI3 p {
  background-color: transparent !important; 
  color: white !important;
  font-size: 14px;
}

.Hero_statusBox__32aiA {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
}


.Hero_subTitle__3wna6 {
  padding: 20px; 
  font-size: 28px; 
  text-align: center !important;
}

/* MEDIA QUERY STARTS HERE */
@media only screen and (max-width: 650px) {


  .Hero_generalInfo__ZYSLo {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      width: 100%;
      height: 100vh;
      
      padding-right: 20px;

  }

  .Hero_birdInfo__3aFO9 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100vh !important;
}
  .Hero_imgContainer__1wZHZ {
    width: 100% !important;
   
  }

  .Hero_birdImg__3hkjo {
      width: 100% !important;
      object-fit: cover;
     
      
  }
  
  .Hero_birdStat__YGsgh {
      width: 100%;
      padding: 40px 20px;
  
  }

  .Hero_title__jxJj6 {
    font-size: 80px !important;
    line-height: 80px !important;
    text-align: center !important;
  }

  .Hero_identification__1PEI3 {
    display: none;
  }

  .Hero_comName__2sk7D {
    padding-top: 20px;
  }

}

.utils_green__g_0Sb {
    width: 35px;
    height: 35px;
    background: #3E6947;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
   
  }
  
  
  .utils_red__3jLnw {
    width: 35px;
    height: 35px;
    background: rgb(235, 17, 17);
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    
  }
  
  .utils_yellow__2zwVV {
    width: 35px;
    height: 35px;
    background: #F9E814;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    
  }
  
  .utils_yellowishGreen__3qv1J{
    width: 35px;
    height: 35px;
    background: #CCE226;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    
  }
  
  .utils_gray__2v2j6 {
    width: 35px;
    height: 35px;
    background: #D1D1C6;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    
  }
.RecentNews_featuresContainer__262yH {
  margin: 0px auto;
  width: 100%;
  padding: 0px 60px 20px 60px;

 
}

/*
    AUTO GRID
    Set the minimum item size with `--auto-grid-min-size` and you'll
    get a fully responsive grid with no media queries.
  */
.RecentNews_autoGrid__1AMod {
  --auto-grid-min-size: 16rem;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
}

.RecentNews_items__3fLE5 {
  width: 100%;
}

.RecentNews_paragraph__1fQv3 {
  width: 60%;
  margin: 0px auto;
  padding-bottom: 40px;
  text-align: center;
}

.RecentNews_listItem__3Zj-k {
  padding: 0rem 0rem;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 1.2rem;
  list-style-type: none;
  text-align: left !important;
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  border-radius: 8px;
}

.RecentNews_listItem__3Zj-k h2 {
  text-align: center;
  padding: 10px 25px;
  text-align: left;
  font-size: 16px;
  line-height: 23px;
}

.RecentNews_listItem__3Zj-k h2:hover {
  color: #426e4b;
}

.RecentNews_listItem__3Zj-k p {
  text-align: center;
  padding: 10px 25px;
  text-align: left;
}

.RecentNews_listItem__3Zj-k:hover {
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
 
}

.RecentNews_hrLine__3N8c3 {
  width: 70%;
  margin: 20px 0px;
  border-top: 1px 0px 0px 0px solid rgb(252, 252, 252);
  height: 1px;
  margin: 15px auto;
}

.RecentNews_logoContainer__2QqU2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.RecentNews_imgThumbnail__37NB9 {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  height: 30vh;
}
.RecentNews_imgThumbnail__37NB9:hover {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: pointer;
}

.RecentNews_authorAndDate__1usdM {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0px 15px;
}

.RecentNews_authorAndDate__1usdM h3 {
  color: #0d9b7f;
  font-size: 10px;
}

.RecentNews_author__3UjuG {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.RecentNews_pagination__dgSmt {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 45px 0px;
  
}




.RecentNews_bg__1O9EZ {
  background-color: #1ABC9C;
}

.RecentNews_pageNum__3mI_R:focus {
  background-color: #1ABC9C;
  color: white;
}

.RecentNews_loadMore__2dicA {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 30px
}

.RecentNews_loadMoreBtn__14UT3 {
  border: 2px solid #195728;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background-color: white;
  margin-top: 20px;
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  font-family: DM Sans;
}

.RecentNews_loadMoreBtn__14UT3:hover {
  background-color: #195728;
  color: white;
  border: 2px solid white;
}


.RecentNews_specieInfo__SbLm7 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}



@media all and (max-width: 768px) {
  .RecentNews_featuresContainer__262yH {
    margin: 100px auto;
    width: 100%;
    display: block;
    padding: 5px 8px;
  }

  .RecentNews_paragraph__1fQv3 {
    width: 90%;
  }

  .RecentNews_items__3fLE5 {
    width: 100%;
  }

  .RecentNews_imgThumbnail__37NB9 {
    height: 30vh;
  }
}


.SpeciesDetail_birdInfo__2W0nn {
  display: -webkit-flex;
  display: flex;
  font-family: Roboto;
  box-shadow: 1px 6px 15px rgba(45, 55, 71, 0.285457), 
  -6px -19px 21px rgba(244, 248, 251, 0.345662), 
  -9px -5px 14px rgba(246, 251, 255, 0.741505), 
  -5px -5px 14px rgba(255, 255, 255, 0.0155704);
  min-height: 80vh;
}

.SpeciesDetail_birdImg__1MswQ {
  width: 50%;
  object-fit: cover;
}

.SpeciesDetail_birdStat__3gsxp {
  width: 50%;
  padding: 100px;
  background: #1C2E20;
}

.SpeciesDetail_comName__2p5_k {
  margin-top: 10px;
  font-size: 55px;
  line-height: 50px;
  color: #fdfffb;
}



.SpeciesDetail_map__3MIwf, .SpeciesDetail_content__3NSa- {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  text-align: center;
  /* border: 1px solid black; */
}

.SpeciesDetail_maps__1dSbW {
  width: 100%; 
  object-fit: cover;
  object-position: 20% 20%; 
  display: block;
}

.SpeciesDetail_statusContainer__3xtux {
    margin-right: 1px;
    margin-top: 22px;
    height: 35px;
    width: 35px
}

.SpeciesDetail_sciName__1w6vo {
  font-size: 22px;
  font-style: italic;
  margin: 20px 0px;
  color: #ffffff;
}

.SpeciesDetail_birdStat__3gsxp .SpeciesDetail_identification__Vfe1V p {
  color: white !important;
}

.SpeciesDetail_identification__Vfe1V span {
  background-color: transparent !important; 
  color: white !important;
  font-size: 14px;
}



.SpeciesDetail_generalInfo__2zFJo{
  display: -webkit-flex;
  display: flex;
  width: 90%;
  margin: 50px auto;
}

.SpeciesDetail_details__2jG-0 {
  padding-right: 40px;
  font-family: Roboto;
}

.SpeciesDetail_media__Nk00e {
  margin-top: 50px;
}

.SpeciesDetail_thumbnails__Rf-xc {
  display: -webkit-flex;
  display: flex;
  margin: 10px;
}

.SpeciesDetail_thumbnails__Rf-xc img {
  width: 250px;
  margin-right: 10px;
}


.SpeciesDetail_topic__Myh85 {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid rgb(230, 230, 230);
  margin-bottom: 15px;
  margin-top: 20px;
}

.SpeciesDetail_iconBox__2xjKp {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: solid 1px rgb(228, 228, 228);
  border-radius: 4px;
  padding: 5px;
  background: white;
  margin-right: 20px;
  margin-top: -10px;
  width: 70px;
  height: 70px;
  position: relative;
}


/* MEDIA QUERY STARTS HERE */
@media only screen and (max-width: 650px) {


  .SpeciesDetail_generalInfo__2zFJo {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

  }

  .SpeciesDetail_birdInfo__2W0nn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
}
  .SpeciesDetail_imageConatiner__1x7YS {
    width: 100%;
  }

  .SpeciesDetail_birdImg__1MswQ {
      width: 100%;
     
      
  }
  
  .SpeciesDetail_birdStat__3gsxp {
      width: 100%;
      padding: 80px 20px;
  
  }

  .SpeciesDetail_taxonomy__2h7w3 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      padding: 10px 0px;
      width: 100%;
  }

  .SpeciesDetail_orders__1Hwd3, .SpeciesDetail_families__3vPpL, .SpeciesDetail_geniuses__Pf9cc {
     
      font-size: 14px;
      border-right: 1px solid rgb(67, 80, 62);
      padding: 0px;
      text-align: center;
      min-width: 80px;
  }

  .SpeciesDetail_comName__2p5_k {
      margin-top: -10px;
      font-size: 38px;
      color: #d3fca4;
      line-height: 35px;
      padding-bottom: 20px;
  }

  .SpeciesDetail_statBox__2cOd8 {
      display: none;
  }



}

/* MEDIA QUERY STOPS HERE */
.NewSpecies_layout__2OPLv {
    width: 80%;
    margin: 0 auto;
    padding: 100px 0px;
}
.Uploader_dropzone__3PVvF {
  height: 4rem;
  width: 100%;
  margin: 0.5rem;
  padding: 1rem;
  border: 2px dashed rgb(182, 182, 182);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.Uploader_active__13oOx {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: rgb(21, 88, 233);
  color: white;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 5px;
}

.BirdList_layout__3bjZ0 {
  
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.BirdList_coverImg__3Gr23 {
    width: 100%;
    padding-top: 50px;
}

.BirdList_coverImg__3Gr23 img {
  height: 200px;
  width: 100%;
  object-fit: cover;

}

.BirdList_header__2jhvh {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.BirdList_topic__mO0bJ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.BirdList_number__3elJm {
    margin-top: -70px;
    width: 120px;
    height: 120px;
    background-color: #3E6947;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
    
}
.BirdList_topic__mO0bJ h3 {
    line-height: 40px;
    font-size: 50px;
    color: white;
}

.BirdList_topic__mO0bJ p {
    padding-top: 10px;
    line-height: 16px;
    font-size: 13px;
    color: white;
}

.BirdList_header__2jhvh input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.BirdList_header__2jhvh input:focus {
    border: solid 2px rgb(0, 148, 79);
}

.BirdList_searchBar__1qDMV {
    display: -webkit-flex;
    display: flex;
}

.BirdList_searchBar__1qDMV button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.BirdList_searchBar__1qDMV button:hover {
    background-color: #33583b;
}

.BirdList_cardContainer__2CmNk {
    width: 65%;
    margin: 0px auto;
}


.BirdList_pagination__xDRZQ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
}


.BirdList_pageNum__1IiDV {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.BirdList_bg__2oI-2 {
    background-color: #3e6947;
}

.BirdList_pageNum__1IiDV:focus {
    background-color: #3e6947;
    color: white;
}








.BirdList_featuresContainer__22KoH {
    margin: 0px auto;
    width: 100%;
    padding: 0px 60px 20px 60px;
  
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .BirdList_autoGrid__bpjyH {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .BirdList_items__12-8b {
    width: 100%;
  }
  
  .BirdList_paragraph__36GQW {
    width: 60%;
    margin: 0px auto;
    padding-bottom: 40px;
    text-align: center;
  }
  
  .BirdList_listItem__2E5qL {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  .BirdList_specieInfo__1m08G {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  
  .BirdList_listItem__2E5qL h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .BirdList_listItem__2E5qL h2:hover {
    color: #426e4b;
  }
  
  .BirdList_listItem__2E5qL p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .BirdList_listItem__2E5qL:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .BirdList_hrLine__39vde {
    width: 70%;
    margin: 20px 0px;
    border-top: 1px 0px 0px 0px solid rgb(252, 252, 252);
    height: 1px;
    margin: 15px auto;
  }
  
  .BirdList_logoContainer__3PXZM {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .BirdList_imgThumbnail__3qomd {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .BirdList_imgThumbnail__3qomd:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  .BirdList_authorAndDate__1PtOR {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0px 15px;
  }
  
  .BirdList_authorAndDate__1PtOR h3 {
    color: #0d9b7f;
    font-size: 10px;
  }
  
  .BirdList_author__3nawz {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .BirdList_pagination__xDRZQ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 45px 0px;
    
  }
  
  
  
  
  .BirdList_bg__2oI-2 {
    background-color: #1ABC9C;
  }
  
  .BirdList_pageNum__1IiDV:focus {
    background-color: #1ABC9C;
    color: white;
  }
  
  .BirdList_loadMore__mu4KX {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 30px
  }
  
  .BirdList_loadMoreBtn__1q-KN {
    border: 1px solid #195728;
    padding: 20px 30px;
    border-radius: 50px;
    font-size: 18px;
    cursor: pointer;
    background-color: white;
    margin-top: 20px;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    font-family: DM Sans;
  }
  
  .BirdList_loadMoreBtn__1q-KN:hover {
    background-color: #195728;
    color: white;
    border: 2px solid white;
  }
  
  
  @media all and (max-width: 768px) {
    .BirdList_featuresContainer__22KoH {
      margin: 100px auto;
      width: 100%;
      display: block;
      padding: 5px 8px;
    }
  
    .BirdList_paragraph__36GQW {
      width: 90%;
    }
  
    .BirdList_items__12-8b {
      width: 100%;
    }
  
    .BirdList_imgThumbnail__3qomd {
      height: 30vh;
    }
  }

  


@media all and (max-width: 768px) {
    .BirdList_featuresContainer__22KoH {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .BirdList_paragraph__36GQW {
      width: 100%;
    }

    .BirdList_header__2jhvh {
      width: 95%;

  }

  .BirdList_cardContainer__2CmNk {
    width: 98%;
}

.BirdList_searchBar__1qDMV button {
  width: 30%;
}

  
  }


.Equipment_layout__3PW-4 {
    padding-top: 70px;
    background-color: rgb(241, 241, 241);
}


.Equipment_pageContainer__226Tu {
    /* padding-left: 150px; */
    background-color: #EEEEEE;
    padding-bottom: 96px;
    min-height: 85%;
    min-height: calc(100% - 186px);
  }
  
  
  .Equipment_stat__tDD9J {
    margin-top: 30px;
    padding-bottom: 2px;
  }
  
  
  .Equipment_intro__2JbdO {
    width: 60%;
    margin: 50px auto;
  }


  .Equipment_searchBar__sCkwM button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.Equipment_searchBar__sCkwM button:hover {
    background-color: #33583b;
}

.Equipment_searchBar__sCkwM input {
  padding: 18px;
  font-size: 16px;
  border: solid 1px gray;
  border-radius: 50px 0px 0px 50px;
  width: 80%;
  outline: none;
  margin: 25px 0px;
  
}

.Equipment_searchBar__sCkwM input:focus {
  border: solid 2px #3e6947;
}



.Equipment_pagination__1MRL8 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 45px 0px;
  
}


.Equipment_pageNum__1EL0D {
margin: 2px;
width: 25px;
height: 25px;
border-radius: 50%;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
cursor: pointer;
border: none;
outline: none;
background-color: white;
}
.Equipment_bg__2-KTy {
  background-color: #3E6947;
}

.Equipment_pageNum__1EL0D:focus {
  background-color: #3E6947;
  color: white;
}


  
      .Equipment_autoGrid__39jFE {
        --auto-grid-min-size: 16rem;
        width: 95%;
        margin: 10px auto;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0.5rem;
      }
       
      .Equipment_listItem__iHIeD {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        min-width: 120px;
        height: 110px;
        padding: 1rem;
        list-style-type: none;
        text-align: center;
        border-bottom: 1px solid rgb(184, 184, 184);
        border-radius: 4px;
        background-color: white;
      }
    
  
      
      .Equipment_listItem__iHIeD h2 {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 10px;
        padding: 0px 25px;
        cursor: pointer;
        color: rgb(61, 61, 61)
      }
      
      
      .Equipment_listItem__iHIeD p {
        text-align: center;
        font-size: 40px;
        color: white;
        line-height: 30px;
        text-align: left;
        padding: 0px 25px 10px 25px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        cursor: pointer;
        color: rgb(61, 61, 61)
      }
      
      .Equipment_listItem__iHIeD:hover {
        background-color: #f8f8f8;
       
      }
      
      .Equipment_listItem__iHIeD:hover h2 {
        color: rgb(0, 0, 0);
       
      }
      
  
      .Equipment_addBtnContainer__16FnN {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        padding-bottom: 15px;
      }

      .Equipment_button__2KeJt {
        padding: 17px 25px;
        border: solid 1px #3e6947;
        border-radius: 50px;
        cursor: pointer;
        background-color: white;
        box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);
        font-family: 'DM Sans' sans-serif;
        font-size: 15px
}

.Equipment_button__2KeJt:hover {
  background-color: #e7ffec;
}

      
      .Equipment_grid__1iWYw {
        --auto-grid-min-size: 20rem;
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0rem;
      }
  
  
        
      .Equipment_entry__2XHC- {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        min-width: 120px;
        height: 60px;
        padding: 1rem 2rem;
        list-style-type: none;
        text-align: left;
        border-bottom: 1px solid rgb(224, 224, 224);
        /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
        border-radius: 0px;
        background-color: white;
      }
  
  
      .Equipment_grid__1iWYw button {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: none;
        background-color: #1976d2;
        color: white;
         box-shadow: 0 3px 6px rgba(0, 0, 0, 0.068), 0 3px 6px rgba(0, 0, 0, 0.096);
      }


/* ################################################# */


.Equipment_equipmentList__3DNzr {
    width: 97%;
    margin: 10px auto;
    margin-bottom: 20px;
}

      .Equipment_autoGrid2__mWOKe {
        --auto-grid-min-size: 10rem;
      
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 1rem;
      }
      
      .Equipment_items2__3CltG {
        width: 100%;
      }
      
      .Equipment_paragraph__23AoJ {
        width: 60%;
        margin: 0px auto;
        padding-bottom: 40px;
        text-align: center;
      }
      
      .Equipment_listItem2__3PkYB {
        padding: 0rem 0rem;
        padding-bottom: 1rem;
        text-align: center;
        font-size: 1.2rem;
        list-style-type: none;
        text-align: left !important;
        box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
        border-radius: 8px;
        background-color: white;
      }
      
      .Equipment_listItem2__3PkYB h2 {
        text-align: center;
        padding: 10px 25px;
        text-align: left;
        font-size: 16px;
        line-height: 23px;
      }
      
      .Equipment_listItem2__3PkYB h2:hover {
        color: #426e4b;
      }
      
      .Equipment_listItem2__3PkYB p {
        text-align: center;
        padding: 10px 25px;
        text-align: left;
      }
      
      .Equipment_listItem2__3PkYB:hover {
        box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
       
      }

      .Equipment_imgThumbnail__3uz8d {
        width: 100%;
        border-radius: 8px 8px 0px 0px;
        object-fit: cover;
        height: 20vh;
      }
      .Equipment_imgThumbnail__3uz8d:hover {
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
      }

      .Equipment_specieInfo__24sCY {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-around;
                justify-content: space-around;
      }
      

      .Equipment_green__2VgjQ {
        width: 40px;
        height: 40px;
        background: #3E6947;
        padding: 8px;
        border-radius: 50%;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        color: white;
        font-size: 12px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
       
       
      }

      .Equipment_btnContainer__3k3yC {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          border-top: 1px solid rgb(230, 230, 230);
          padding: 5px;
      }

      .Equipment_submitBtn__2du6j {
          padding: 8px;
          border: solid #3e6947 1px;
          border-radius: 50px;
          background-color: white;
          color: black;
          cursor: pointer;
          font-family: "DM Sans", sans-serif;
      }

      .Equipment_submitBtn__2du6j:hover {
          background-color: #3e6947;
          color: white;
      }
      /* ###################################### */
      
  
    
      @media all and (max-width: 768px) {
      
        .Equipment_items__1A14K {
          width: 100%;
        }
    
        .Equipment_intro__2JbdO {
          width: 90%;
        }
      
        .Equipment_grid__1iWYw {
          --auto-grid-min-size: 20rem;
          width: 100%;
        }
  
       
       
      }
.Status_container__2erG5 {
  display: block;
  position: relative;
  padding: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Status_container__2erG5 p {
  padding-left: 35px;
  margin-top: -3px;
  text-align: left;
}

/* Hide the browser's default radio button */
.Status_container__2erG5 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.Status_checkmark__2DvQ5 {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  background-color: #eee;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* On mouse-over, add a grey background color */
.Status_container__2erG5:hover input ~ .Status_checkmark__2DvQ5 {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.Status_container__2erG5 input:checked ~ .Status_checkmark__2DvQ5 {
  background-color: #3e6947;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Status_checkmark__2DvQ5:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.Status_container__2erG5 input:checked ~ .Status_checkmark__2DvQ5:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.Status_container__2erG5 .Status_checkmark__2DvQ5:after {
  left: 13px;
  top: 6px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Uploader_dropzone__1Kf5f {
  height: 4rem;
  width: 100%;
  margin: 0.5rem;
  padding: 1rem;
  border: 2px dashed rgb(182, 182, 182);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.Uploader_active__1z6QT {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: rgb(21, 88, 233);
  color: white;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 5px;
}

.Home_featuresContainer__2396i {
    margin: 0px auto;
    padding: 130px 30px 60px 30px;
    background-color: #F5F5F5;
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .Home_autoGrid__14KL2 {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Home_items__3vL6x {
    width: 100%;
  }
  
  .Home_paragraph__2NIoM {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .Home_listItem__1oFAH {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: white;
  }
  
  .Home_listItem__1oFAH h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .Home_listItem__1oFAH h2:hover {
    color: #50835a;
  }
  
  .Home_listItem__1oFAH p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .Home_listItem__1oFAH:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .Home_imgThumbnail__j_Jjc {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .Home_imgThumbnail__j_Jjc:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .Home_featuresContainer__2396i {
      width: 90%;
      display: block;
    }
  
    .Home_items__3vL6x {
      width: 100%;
    }
  
    .Home_paragraph__2NIoM {
      width: 100%;
     
    }
  
    .Home_imgThumbnail__j_Jjc {
      height: 50vh;
    }
  }
  
.PointCount_featuresContainer__2RPOd {
    margin: 0px auto;
    padding: 90px 30px 60px 30px;
    /* background-color: #F5F5F5; */
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .PointCount_autoGrid__IIJwY {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .PointCount_items__3JOlw {
    width: 100%;
  }
  
  .PointCount_paragraph__ngrxI {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  
  .PointCount_listItem__2DdTo {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: white;
  }
  
  .PointCount_listItem__2DdTo h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .PointCount_listItem__2DdTo h2:hover {
    color: #50835a;
  }
  
  .PointCount_listItem__2DdTo p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .PointCount_listItem__2DdTo:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .PointCount_imgThumbnail__LZBA_ {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .PointCount_imgThumbnail__LZBA_:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .PointCount_featuresContainer__2RPOd {
      padding: 90px 0px 60px 0px;;
      width: 100%;
      display: block;
    }
  
    .PointCount_items__3JOlw {
      width: 100%;
    }
  
    .PointCount_paragraph__ngrxI {
      width: 100%;
     
    }
  
    .PointCount_imgThumbnail__LZBA_ {
      height: 50vh;
    }
  }
  



.ArrivalDepature_featuresContainer__rQf7k {
    margin-top: 20px;
    z-index: 10;
    position: relative;
    width: 95vw;
    padding-left: 20%;
    
  }
  
  
  /*
    AUTO GRID
    Set the minimum item size with `--auto-grid-min-size` and you'll
    get a fully responsive grid with no media queries.
  */
  .ArrivalDepature_autoGrid__3sklT {
    --auto-grid-min-size: 13rem;
    width: 100%;
    margin: 0px auto;
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 0rem;
  }
  
  
  
  .ArrivalDepature_paragraph__eHnTR p {
    text-align: left;
  }
  
  .ArrivalDepature_listItem__18l3I {
    padding: 0rem 0rem 2rem 0rem;
    margin: 5px;
    text-align: center;
    /* font-size: 18px; */
    list-style-type: none;
    background-color: white;
   box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  }
  
  .ArrivalDepature_listItem__18l3I .ArrivalDepature_svg__3oL-s {
    padding-top: 1.5rem;
  }
  

  .ArrivalDepature_listItem__18l3I:hover {
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
    cursor: pointer;
    background-color: #eeffdb;
  }
  
  .ArrivalDepature_listItem__18l3I p {
    text-align: center !important;
    font-family: Roboto;
    font-size: 45px;
    color: black;
  }
  
  .ArrivalDepature_listItem__18l3I h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #3e6947
  }
  
  .ArrivalDepature_listItem__18l3I:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    cursor: pointer;
  }
  
  .ArrivalDepature_hrLine__11QnH {
    width: 70%;
    margin: 20px 0px;
    border-top: 1px 0px 0px 0px solid rgb(218, 218, 218);
    height: 1px;
  }
  
  /* .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  
  @media all and (max-width: 768px) {
    .ArrivalDepature_featuresContainer__rQf7k {
      margin: 0px auto;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 0px;
    }
  
    .ArrivalDepature_items__3hQDv {
      width: 100%;
    }
  
    .ArrivalDepature_paragraph__eHnTR {
      width: 100%;
      padding: 0px 10px;
      padding-bottom: 50px;
    }
  
  
    .ArrivalDepature_autoGrid__3sklT {
      --auto-grid-min-size: 8rem;
      width: 100%;
    }
  }
.Founder_container__K8opq {
    text-align: center;
  }
  
  .Founder_containerHead__1t3WB {
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 220px;
  
    /* background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(12, 12, 29, 0.6306897759103641) 38%,
      rgba(0, 212, 255, 1) 100%
    ); */
  }
  
  .Founder_menu__2RvNB p {
    padding: 0px;
  }
  
  .Founder_profilePicture__3khZP {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .Founder_profilePicture__3khZP img {
    height: 330px;
    width: 330px;
    border-radius: 100%;
    margin-top: -90px;
    object-fit: cover;
    border: solid 10px rgb(238, 237, 237);
  }
  

  .Founder_name__2hZJU {
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    padding-top: 30px;
  }

  .Founder_works__1FLV- {
    padding: 10px;
    border-top: 1px solid rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
    width: 50%;
    margin: 10px auto;
    color: gray;
  }
  
  .Founder_location__2S90s {
    color: rgb(12, 114, 46);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .Founder_bio__2cxkI div {
    padding: 20px;
    width: 60%;
    margin: 0px auto;
  }
  
  .Founder_stats__1nwFP {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 50%;
    margin: 0px auto;
    padding-bottom: 30px;
  }
  
  .Founder_stats__1nwFP p {
    color: gray;
    font-size: 12px;
    margin-bottom: -10px;
    text-transform: uppercase;
  }
  
  .Founder_statBox__1poHc {
    margin: 10px;
  }
  
  .Founder_buttons__bFFHg {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  /* ADD NEW DROPDOWN BUTTON */
  
  .Founder_dropdown__btX_i {
    display: inline-block;
    position: relative;
    margin: 10px;
  }
  
  .Founder_ddButton__3rJGa {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .Founder_ddButton__3rJGa:after {
    /* content: ""; */
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  
  .Founder_ddButton__3rJGa:hover {
    background-color: #eeeeee;
  }
  
  .Founder_ddInput__23yeW {
    display: none;
  }
  
  .Founder_ddMenu__3fbtO {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
  }
  
  .Founder_ddInput__23yeW + .Founder_ddMenu__3fbtO {
    display: none;
  }
  
  .Founder_ddInput__23yeW:checked + .Founder_ddMenu__3fbtO {
    display: block;
  }
  
  .Founder_ddMenu__3fbtO li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .Founder_ddMenu__3fbtO li:hover {
    background-color: #f6f6f6;
  }
  
  .Founder_ddMenu__3fbtO li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  
  .Founder_ddMenu__3fbtO li.Founder_divider__DHdRo {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
  
  .Founder_editButton__29jxx {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    margin: 10px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
  }
  


  @media all and (max-width: 768px) {
    .Founder_bio__2cxkI p {
      padding: 20px;
      width: 95%;
      margin: 0px auto;
    }
  
  }
.CapacityBuilding_layout__swmIg {
    margin: 0px auto;
    width: 100%;
    padding: 80px 0px;
    
}

.CapacityBuilding_header__Midzt {
    text-align: center;
}

.CapacityBuilding_header__Midzt h1 {
    font-size: 70px;
    line-height: 70px;
    padding: 50px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
}

.CapacityBuilding_header__Midzt p {
    font-size: 35px;
    line-height: 40px;
    padding: 30px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
}

/* .body {
    width: 70%;
    margin: 0px auto;
    text-align: left;
    line-height: 30px !important;
}

.body p {
    text-align: left;
} */


.CapacityBuilding_section__5RDWe {
    display: grid;
  -webkit-justify-content: center;
          justify-content: center;
}

  .CapacityBuilding_featuredImage__23Jo_ {
        width: 100%;
        height: 500px;
        object-fit: cover;
  }


  .CapacityBuilding_section__5RDWe h1 {
    font-size: 50px;
    line-height: 50px;
    padding: 40px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.CapacityBuilding_section__5RDWe p {
    font-size: 20px;
    line-height: 30px;
    padding: 30px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
}


.CapacityBuilding_pubCharts__gHxy4 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: flex-start;
            align-content: flex-start;

}

.CapacityBuilding_innerSection__2oPJt {
    width: 100%;
    margin: 0 auto;
}

.CapacityBuilding_icon__1Wfdv {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 60px;
    width: 60px;
    background-color: #3e6947;
    box-shadow:
    2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07)
  ;
}

.CapacityBuilding_pubCharts__gHxy4 p {
    font-size: 30px;
    line-height: 40px !important;
    padding:40px;
    line-height: 55px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
    font-weight: 700;
}

.CapacityBuilding_text__1d9d3 {
    width: 45%;
    padding: 30px 0px;
    border-radius: 6px;
    border: solid 2px gray;
    box-shadow:
  2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  100px 100px 80px rgba(0, 0, 0, 0.07);  
}

.CapacityBuilding_chart__20ivD {
    width: 50%;
}

.CapacityBuilding_h1__3Gc0D {
    font-size: 50px;
    line-height: 50px;
    padding: 60px 200px 0px 200px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

@media all and (max-width: 768px) {
    .CapacityBuilding_layout__swmIg {
        width: 100%;
    }
    

.CapacityBuilding_section__5RDWe h1 {
    font-size: 26px;
    line-height: 30px;
    padding: 10px;
    letter-spacing: 0px;

}


.CapacityBuilding_header__Midzt h1 {
    font-size: 30px;
    line-height: 40px;
    padding: 10px;

}

.CapacityBuilding_h1__3Gc0D {
    font-size: 23px;
    line-height: 26px;
    padding: 30px 20px;
}

.CapacityBuilding_header__Midzt p {
    font-size: 18px;
    line-height: 23px;
    padding: 10px;

}


.CapacityBuilding_featuredImage__23Jo_ {
    width: 100%;
    height: 20vh;
    object-fit: cover;
}


.CapacityBuilding_pubCharts__gHxy4 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: flex-start;
            align-content: flex-start;

}

.CapacityBuilding_text__1d9d3 {
    width: 95%;
}

.CapacityBuilding_chart__20ivD {
    width: 100%;
}

.CapacityBuilding_section__5RDWe p {
    font-size: 20px;
    line-height: 30px;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    text-align: center !important;
}

}
.KeyRoles_featuresContainer__2d_Fm {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;
   
  }
  
  .KeyRoles_autoGrid__2HoQV {
    /* formerly 25rem */
    --auto-grid-min-size: 20rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .KeyRoles_items__ZBb5y {
    width: 100%;
  }
  
  .KeyRoles_paragraph__3JiVN {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .KeyRoles_listItem__5dZqu {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #F7FFEF;
    border: 1px solid #d1d1d1;
  }
  
  .KeyRoles_listItem__5dZqu h2 {
    text-align: center !important;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .KeyRoles_listItem__5dZqu h2:hover {
    color: #50835a;
  }
  
  .KeyRoles_listItem__5dZqu p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .KeyRoles_listItem__5dZqu:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .KeyRoles_imgThumbnail__1m1Lv {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 300px;
  }
  .KeyRoles_imgThumbnail__1m1Lv:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .KeyRoles_featuresContainer__2d_Fm {
      width: 100%;
      display: block;
    }
  
    .KeyRoles_items__ZBb5y {
      width: 100%;
    }
  
    .KeyRoles_paragraph__3JiVN {
      width: 100%;
     
    }
  
    .KeyRoles_imgThumbnail__1m1Lv {
      height: 50vh;
    }

    
  }
  
.PhdStudents_featuresContainer__1Fj3x {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;

   
  }
  
  .PhdStudents_autoGrid__CtKHO {
    --auto-grid-min-size: 20rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .PhdStudents_items__2ivI4 {
    width: 100%;
  

  }
  
  .PhdStudents_paragraph___YC1l {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .PhdStudents_listItem__2ABLl {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #F7FFEF;
  }
  
  .PhdStudents_listItem__2ABLl h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  
  .PhdStudents_listItem__2ABLl h2:hover {
    color: #50835a;
  }
  
  .PhdStudents_listItem__2ABLl p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .PhdStudents_listItem__2ABLl:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .PhdStudents_imgThumbnail__1O-9z {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 300px;
  }
  .PhdStudents_imgThumbnail__1O-9z:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .PhdStudents_featuresContainer__1Fj3x {
      width: 90%;
      display: block;
    }
  
    .PhdStudents_items__2ivI4 {
      width: 100%;
    }
  
    .PhdStudents_paragraph___YC1l {
      width: 100%;
     
    }
  
    .PhdStudents_imgThumbnail__1O-9z {
      height: 50vh;
    }
  }
  
.Research_featuresContainer__1rPdX {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;
   
  }
  
  .Research_autoGrid__2nolB {
    --auto-grid-min-size: 20rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Research_items__2yBG7 {
    width: 100%;
  }
  
  .Research_paragraph__3rtij {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .Research_listItem__1cDJl {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #F7FFEF;
  }
  
  .Research_listItem__1cDJl h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  
  .Research_listItem__1cDJl h2:hover {
    color: #50835a;
  }
  
  .Research_listItem__1cDJl p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .Research_listItem__1cDJl:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .Research_imgThumbnail__1Z1RM {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .Research_imgThumbnail__1Z1RM:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .Research_featuresContainer__1rPdX {
      width: 90%;
      display: block;
    }
  
    .Research_items__2yBG7 {
      width: 100%;
    }
  
    .Research_paragraph__3rtij {
      width: 100%;
     
    }
  
    .Research_imgThumbnail__1Z1RM {
      height: 50vh;
    }
  }
  



.Destination_featuresContainer__1WULX {
    margin-top: -50px;
    z-index: 10;
    position: relative;
    width: 95vw;
    padding-left: 40px;
    
  }
  
  
  /*
    AUTO GRID
    Set the minimum item size with `--auto-grid-min-size` and you'll
    get a fully responsive grid with no media queries.
  */
  .Destination_autoGrid__-mKiP {
    --auto-grid-min-size: 13rem;
    width: 100%;
    margin: 0px auto;
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 0rem;
  }
  
  
  
  .Destination_paragraph__1nMfc p {
    text-align: left;
  }
  
  .Destination_listItem__2oTwR {
    padding: 0rem 0rem 2rem 0rem;
    text-align: center;
    /* font-size: 18px; */
    list-style-type: none;
    background-color: white;
   box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  }
  
  .Destination_listItem__2oTwR .Destination_svg__39Q1U {
    padding-top: 1.5rem;
  }
  

  .Destination_listItem__2oTwR:hover {
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
    cursor: pointer;
    background-color: #eeffdb;
  }
  
  .Destination_listItem__2oTwR p {
    text-align: center !important;
    font-family: Roboto;
    font-size: 45px;
    color: black;
  }
  
  .Destination_listItem__2oTwR h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #3e6947
  }
  
  .Destination_listItem__2oTwR:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    cursor: pointer;
  }
  
  .Destination_hrLine__1V73O {
    width: 70%;
    margin: 20px 0px;
    border-top: 1px 0px 0px 0px solid rgb(218, 218, 218);
    height: 1px;
  }
  
  /* .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  
  @media all and (max-width: 768px) {
    .Destination_featuresContainer__1WULX {
      margin: 0px auto;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 0px;
    }
  
    .Destination_items__vR22W {
      width: 100%;
    }
  
    .Destination_paragraph__1nMfc {
      width: 100%;
      padding: 0px 10px;
      padding-bottom: 50px;
    }
  
  
    .Destination_autoGrid__-mKiP {
      --auto-grid-min-size: 8rem;
      width: 100%;
    }
  }
.Trends_featuresContainer__uBM2x {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;
   
  }
  
  .Trends_autoGrid__X2Dy6 {
    --auto-grid-min-size: 30rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Trends_items__ecrP8 {
    width: 100%;
  }
  
  .Trends_paragraph__4ElpC {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .Trends_listItem__1AWdk {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #eafada;
    border: solid 1px rgb(194, 194, 194);
  }
  
  .Trends_listItem__1AWdk h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  
  .Trends_listItem__1AWdk h2:hover {
    color: #50835a;
  }
  
  .Trends_listItem__1AWdk p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .Trends_listItem__1AWdk:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .Trends_imgThumbnail__21idP {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;


  }
  .Trends_imgThumbnail__21idP:hover {
    -webkit-filter: brightness(97%);
            filter: brightness(97%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .Trends_featuresContainer__uBM2x {
      width: 100%;
      display: block;
    }
  
    .Trends_items__ecrP8 {
      width: 100%;
    }
  
    .Trends_paragraph__4ElpC {
      width: 100%;
     
    }
  
    .Trends_imgThumbnail__21idP {
      height: 70vh;
    }
  }
  
.Sponsors_featuresContainer__2Pi7S {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;
   
  }
  
  .Sponsors_autoGrid__2MZux {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Sponsors_items__Ym04M {
    width: 100%;
  }
  
  .Sponsors_paragraph__3nHrc {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .Sponsors_listItem__2McSC {
    padding: 0rem 0rem;
    list-style-type: none;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  
  .Sponsors_listItem__2McSC:hover {
    /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
    background-color: rgb(245, 255, 242);
    border: solid 2px rgb(199, 255, 182);
   
  }
  
  .Sponsors_imgThumbnail__8Xeye {
    width: 100%;
    object-fit: cover;
    height: 300;
  }
  .Sponsors_imgThumbnail__8Xeye:hover {
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .Sponsors_featuresContainer__2Pi7S {
      width: 100%;
      display: block;
    }
  
    .Sponsors_items__Ym04M {
      width: 100%;
    }
  
    .Sponsors_paragraph__3nHrc {
      width: 100%;
     
    }
  
    .Sponsors_imgThumbnail__8Xeye {
      height: 30vh;
    }
  }
  
.CitizenScience_featuresContainer__3FpT9 {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;
   
  }
  
  .CitizenScience_autoGrid__4rhsa {
    --auto-grid-min-size: 18rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .CitizenScience_items__2g5oc {
    width: 100%;
  }
  
  .CitizenScience_paragraph__29MtM {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .CitizenScience_listItem__2Yd1q {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #F7FFEF;
  }
  
  .CitizenScience_listItem__2Yd1q h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  
  .CitizenScience_listItem__2Yd1q h2:hover {
    color: #50835a;
  }
  
  .CitizenScience_listItem__2Yd1q p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .CitizenScience_listItem__2Yd1q:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .CitizenScience_imgThumbnail__2IytW {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 400;
  }
  .CitizenScience_imgThumbnail__2IytW:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .CitizenScience_featuresContainer__3FpT9 {
      width: 100%;
      display: block;
    }
  
    .CitizenScience_items__2g5oc {
      width: 100%;
    }
  
    .CitizenScience_paragraph__29MtM {
      width: 100%;
     
    }
  
    .CitizenScience_imgThumbnail__2IytW {
      height: 30vh;
    }
  }
  
.Coverage_featuresContainer__3Gjmm {
    margin: 0px auto;
    padding: 0px 30px 60px 30px;
   
  }
  
  .Coverage_autoGrid__1NVOB {
    --auto-grid-min-size: 40rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .Coverage_items__36pYe {
    width: 100%;
  }
  
  .Coverage_paragraph__3VkIN {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .Coverage_listItem__1YG4Q {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #eafada;
    border: solid 1px rgb(194, 194, 194);
  }
  
  .Coverage_listItem__1YG4Q h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  
  .Coverage_listItem__1YG4Q h2:hover {
    color: #50835a;
  }
  
  .Coverage_listItem__1YG4Q ul li p {
    text-align: left !important;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 18px;
  }
  
  .Coverage_listItem__1YG4Q:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .Coverage_imgThumbnail__3Cx4u {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 750px;
  }
  .Coverage_imgThumbnail__3Cx4u:hover {
    -webkit-filter: brightness(95%);
            filter: brightness(95%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .Coverage_featuresContainer__3Gjmm {
      width: 100%;
      display: block;
    }

    .Coverage_autoGrid__1NVOB {
      --auto-grid-min-size: 15rem;

    }
  
    .Coverage_items__36pYe {
      width: 100%;
    }
  
    .Coverage_paragraph__3VkIN {
      width: 100%;
     
    }
  
    .Coverage_imgThumbnail__3Cx4u {
      height: 50vh;
    }
  }
  
.NgoFounders_featuresContainer__2UAmh {
    margin: 0px auto;
    padding: 40px 30px 60px 30px;

   
  }
  
  .NgoFounders_autoGrid__tbOCQ {
    --auto-grid-min-size: 20rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .NgoFounders_items__I6uk4 {
    width: 100%;
  

  }
  
  .NgoFounders_paragraph__1qx9C {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .NgoFounders_listItem__2oXn4 {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: center !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
    background-color: #F7FFEF;
  }
  
  .NgoFounders_listItem__2oXn4 h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  
  .NgoFounders_listItem__2oXn4 h2:hover {
    color: #50835a;
  }
  
  .NgoFounders_listItem__2oXn4 p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .NgoFounders_listItem__2oXn4:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
  .NgoFounders_imgThumbnail__tD5h3 {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 300px;
  }
  .NgoFounders_imgThumbnail__tD5h3:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: pointer;
  }
  
  
  
  @media all and (max-width: 768px) {
    .NgoFounders_featuresContainer__2UAmh {
      width: 90%;
      display: block;
    }
  
    .NgoFounders_items__I6uk4 {
      width: 100%;
    }
  
    .NgoFounders_paragraph__1qx9C {
      width: 100%;
     
    }
  
    .NgoFounders_imgThumbnail__tD5h3 {
      height: 50vh;
    }
  }
  
