.layout {
    padding-top: 150px;
    margin: 0px auto;
    width: 60%;
    padding-bottom: 80px;
    
}

.header {
    text-align: left;
}

.body p {
    text-align: left;
}

.author {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 8px;
    padding-top: 0px;
  }

  .author h3:hover {
      text-align: left;
  }

  .featuredImage {
        width: 100%;
        height: auto;
  }

  .hrLine {
      padding-bottom: 20px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      height: 1px;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    line-height: 14px;
    
}

.user:hover {
    text-decoration: underline;
}

.button {
    padding: 12px 16px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    background-color: white;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
;

}

.button:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}


@media all and (max-width: 768px) {
    .layout {
        width: 80%;
    }
}