.layout {
  
    margin: 0px auto;
    background-color: rgb(250, 250, 250);
    
}

.coverImg {
    width: 100%;
    padding-top: 50px;
}

.header {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.summary {
    text-align: center !important;
}

.topic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.number {
    margin-top: -70px;
    width: 120px;
    height: 120px;
    background-color: #3E6947;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
    
}
.topic h3 {
    line-height: 40px;
    font-size: 50px;
    color: white;
}

.topic p {
    padding-top: 10px;
    line-height: 16px;
    font-size: 13px;
    color: white;
}

.header input {
    padding: 18px;
    font-size: 16px;
    border: solid 1px gray;
    border-radius: 50px 0px 0px 50px;
    width: 80%;
    outline: none;
    margin: 25px 0px;
    
}

.header input:focus {
    border: solid 2px #3e6947;
}

.searchBar {
    display: flex;
}

.searchBar button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.searchBar button:hover {
    background-color: #33583b;
}

.cardContainer {
    width: 65%;
    margin: 0px auto;
}


.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0px;
    
}


.pageNum {
    margin: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
}

.bg {
    background-color: #3e6947;
}

.pageNum:focus {
    background-color: #3e6947;
    color: white;
}


@media all and (max-width: 768px) {
    .featuresContainer {
      margin: 100px auto;
      width: 100%;
      display: block;
    }
    .paragraph {
      width: 100%;
    }

    .header {
      width: 95%;

  }

  .cardContainer {
    width: 98%;
}

.searchBar button {
  width: 30%;
}

  
  }
