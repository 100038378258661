@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
.bigImage {
  height: 85vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  /* background-image: url("https://i.postimg.cc/YS4YNCp2/Amurum-2.jpg"); */
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(16, 39, 27, 0.575);
  /* background: rgba(0, 0, 0, 0.65); */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 20%;
}

.content img {
    height: 130px;
    width: 130px;
    margin-right: 40px;
}

.overlay h1 {
  font-size: 50px;
  line-height: 45px;
  letter-spacing: 0px;
  font-weight: 700px;
  color: white;
  text-align: left;
  width: 70%;
  /* font-family: Roboto; */
  /* font-weight: 100px !important; */
}


.overlay p {
  margin: 0;
    color: white;
    text-align: left;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 20px;
}

.button {
  padding: 15px 20px;
  border-radius: 50px;
  border: solid 2px #ffffff;
  cursor: pointer;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
  color: white;

}

.button:hover {
  background-color: #3E6947;
  color: white;
  border: solid 1px #3E6947;
}


@media all and (max-width: 768px) {
  .overlay h1 {
    font-size: 25px;
    line-height: 25px;
    width: 90%;
    
  }

  .overlay p {
    margin: 0;
    width: 70%;
     
  }
}
