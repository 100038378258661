@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
.bigImage {
  height: 95vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  /* background-image: url("https://i.postimg.cc/YS4YNCp2/Amurum-2.jpg"); */
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(12, 31, 21, 0.678);
  /* background: rgba(0, 0, 0, 0.65); */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.title {
  font-size: 120px !important;
  line-height: 100px !important;
  width: 100%;
  font-weight: 700;

}

.content {
    display: flex;
    align-items: center;
    justify-content: center;

   
}

/* .content img {
    height: 130px;
    width: 130px;
    margin-right: 40px;
} */

.overlay h1 {
  font-size: 50px;
  line-height: 45px;
  letter-spacing: 0px;
  font-weight: 700px;
  color: white;
  text-align: left;
  width: 70%;
  /* font-family: Roboto; */
  /* font-weight: 100px !important; */
}


.overlay p {
  margin: 0;
    color: white;
    text-align: left;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 20px;
}

.button {
  padding: 15px 20px;
  border-radius: 50px;
  border: solid 2px #ffffff;
  cursor: pointer;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
  color: white;

}

.button:hover {
  background-color: #3E6947;
  color: white;
  border: solid 1px #3E6947;
}


.birdInfo {
  display: flex;
  font-family: Roboto;
  box-shadow: 1px 6px 15px rgba(45, 55, 71, 0.285457), 
  -6px -19px 21px rgba(244, 248, 251, 0.345662), 
  -5px -5px 14px rgba(255, 255, 255, 0.0155704);
  height: 95vh;
  
}


.imgContainer {
  width: 50%;
  height: 95vh;
  background-color: #1C2E20;
}

.birdImg {
  object-fit: cover;
  height: 100% !important;
}

.birdStat {
  width: 50%;
  padding: 100px;
  background: #1C2E20;
}

.readmore {
  margin-top: 10px;
  padding: 15px;
  border: solid 2px green;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.readmore:hover {
  background-color: green;
}
/* .birdImg, .birdStat {
  flex-basis: 100%;
  text-align: center;
} */

.comName {
  margin-top: 10px;
  font-size: 55px;
  line-height: 50px;
  color: #fdfffb;
}


.sciName {
  font-size: 22px;
  font-style: italic;
  margin: 20px 0px;
  color: #ffffff;
}


.identification p  {
  text-align: center !important;
}

.identification span {
  background-color: transparent !important; 
  color: white !important;
  font-size: 14px;
}

.identification p {
  background-color: transparent !important; 
  color: white !important;
  font-size: 14px;
}

.statusBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


.subTitle {
  padding: 20px; 
  font-size: 28px; 
  text-align: center !important;
}

/* MEDIA QUERY STARTS HERE */
@media only screen and (max-width: 650px) {


  .generalInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      
      padding-right: 20px;

  }

  .birdInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh !important;
}
  .imgContainer {
    width: 100% !important;
   
  }

  .birdImg {
      width: 100% !important;
      object-fit: cover;
     
      
  }
  
  .birdStat {
      width: 100%;
      padding: 40px 20px;
  
  }

  .title {
    font-size: 80px !important;
    line-height: 80px !important;
    text-align: center !important;
  }

  .identification {
    display: none;
  }

  .comName {
    padding-top: 20px;
  }

}