.featuresContainer {
  margin: 50px auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.imgThumbnail {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  height: 200px;
}

/*
  AUTO GRID
  Set the minimum item size with `--auto-grid-min-size` and you'll
  get a fully responsive grid with no media queries.
*/
.autoGrid {
  --auto-grid-min-size: 12rem;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 0.5fr)
  );
  grid-gap: 1rem;
}

.items {
  width: 50%;
}

.paragraph {
  width: 50%;
  padding-right: 20px;
}

.paragraph p {
  text-align: left;
}

.listItem {
  padding: 0rem 0rem 2rem 0rem;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  list-style-type: none;
  border: 1px solid rgb(223, 223, 223);
  /* box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px; */
}

.listItem:hover {
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  border: 1px solid #83e799;
  cursor: pointer;
}

.listItem p {
  text-align: left;
}

.listItem h2 {
  text-align: center;
  font-size: 16px;
  color: rgb(71, 71, 71);
}

.listItem:hover {
  box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
  cursor: pointer;
}

.hrLine {
  width: 70%;
  margin: 20px 0px;
  border-top: 1px 0px 0px 0px solid rgb(218, 218, 218);
  height: 1px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 768px) {
  .featuresContainer {
    margin: 100px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .items {
    width: 100%;
  }



  .autoGrid {
    --auto-grid-min-size: 15rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }

  .paragraph {
    width: 100%;
    padding: 0px 10px;
    padding-bottom: 50px;
  }


  .imgThumbnail {
    height: 30vh;
  }
}


.button {
  float: left;
  padding: 12px 16px;
  border-radius: 4px;
  border: solid none;
  cursor: pointer;
  background-color: #3e6947;
  color: white;
  box-shadow:
0 2.8px 2.2px rgba(0, 0, 0, 0.02),
0 6.7px 5.3px rgba(0, 0, 0, 0.028),
0 12.5px 10px rgba(0, 0, 0, 0.035),
0 22.3px 17.9px rgba(0, 0, 0, 0.042),
0 41.8px 33.4px rgba(0, 0, 0, 0.05),
0 100px 80px #00000012;
margin-top: 30px;

}

.button:hover {
  background-color: white;
  color: black;
  border: solid 1px #3e6947;
}

