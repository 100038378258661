@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.card {
    text-align: left;
    border: solid rgb(204, 204, 204) 1px;
    /* box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1); */
    padding: 30px 40px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    display: flex;
}

.title {
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    padding-bottom: 8px;
    cursor: pointer;
    word-wrap: break-word;
    
}


.title:hover {
    color: #3e6947;
}

.card p {
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.year {
    color: gray;
    font-size: 13px;
    word-wrap: break-word;
}

.Avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    line-height: 10px;
}

.user:hover {
    text-decoration: underline;
}


@media all and (max-width: 768px) {
    .card {
   
      margin: 3px;
    }
  
  }