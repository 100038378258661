
.layout {
    padding-top: 70px;
    background-color: rgb(241, 241, 241);
}


.pageContainer {
    /* padding-left: 150px; */
    background-color: #EEEEEE;
    padding-bottom: 96px;
    min-height: 85%;
    min-height: -webkit-calc(100% - 186px);
    min-height: -moz-calc(100% - 186px);
    min-height: calc(100% - 186px);
  }
  
  
  .stat {
    margin-top: 30px;
    padding-bottom: 2px;
  }
  
  
  .intro {
    width: 60%;
    margin: 50px auto;
  }


  .searchBar button {
    padding: 18px;
    margin: 25px 0px;
    border-radius: 0px 50px 50px 0px;
    border: solid #3e6947 1px;
    cursor: pointer;
    width: 20%;
    background: #3e6947;  /* #242526; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: white;
    font-size: 16px;
}

.searchBar button:hover {
    background-color: #33583b;
}

.searchBar input {
  padding: 18px;
  font-size: 16px;
  border: solid 1px gray;
  border-radius: 50px 0px 0px 50px;
  width: 80%;
  outline: none;
  margin: 25px 0px;
  
}

.searchBar input:focus {
  border: solid 2px #3e6947;
}



.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0px;
  
}


.pageNum {
margin: 2px;
width: 25px;
height: 25px;
border-radius: 50%;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
cursor: pointer;
border: none;
outline: none;
background-color: white;
}
.bg {
  background-color: #3E6947;
}

.pageNum:focus {
  background-color: #3E6947;
  color: white;
}


  
      .autoGrid {
        --auto-grid-min-size: 16rem;
        width: 95%;
        margin: 10px auto;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0.5rem;
      }
       
      .listItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 120px;
        height: 110px;
        padding: 1rem;
        list-style-type: none;
        text-align: center;
        border-bottom: 1px solid rgb(184, 184, 184);
        border-radius: 4px;
        background-color: white;
      }
    
  
      
      .listItem h2 {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 10px;
        padding: 0px 25px;
        cursor: pointer;
        color: rgb(61, 61, 61)
      }
      
      
      .listItem p {
        text-align: center;
        font-size: 40px;
        color: white;
        line-height: 30px;
        text-align: left;
        padding: 0px 25px 10px 25px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        cursor: pointer;
        color: rgb(61, 61, 61)
      }
      
      .listItem:hover {
        background-color: #f8f8f8;
       
      }
      
      .listItem:hover h2 {
        color: rgb(0, 0, 0);
       
      }
      
  
      .addBtnContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
      }

      .button {
        padding: 17px 25px;
        border: solid 1px #3e6947;
        border-radius: 50px;
        cursor: pointer;
        background-color: white;
        box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);
        font-family: 'DM Sans' sans-serif;
        font-size: 15px
}

.button:hover {
  background-color: #e7ffec;
}

      
      .grid {
        --auto-grid-min-size: 20rem;
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0rem;
      }
  
  
        
      .entry {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 120px;
        height: 60px;
        padding: 1rem 2rem;
        list-style-type: none;
        text-align: left;
        border-bottom: 1px solid rgb(224, 224, 224);
        /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
        border-radius: 0px;
        background-color: white;
      }
  
  
      .grid button {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: none;
        background-color: #1976d2;
        color: white;
         box-shadow: 0 3px 6px rgba(0, 0, 0, 0.068), 0 3px 6px rgba(0, 0, 0, 0.096);
      }


/* ################################################# */


.equipmentList {
    width: 97%;
    margin: 10px auto;
    margin-bottom: 20px;
}

      .autoGrid2 {
        --auto-grid-min-size: 10rem;
      
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 1rem;
      }
      
      .items2 {
        width: 100%;
      }
      
      .paragraph {
        width: 60%;
        margin: 0px auto;
        padding-bottom: 40px;
        text-align: center;
      }
      
      .listItem2 {
        padding: 0rem 0rem;
        padding-bottom: 1rem;
        text-align: center;
        font-size: 1.2rem;
        list-style-type: none;
        text-align: left !important;
        box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
        border-radius: 8px;
        background-color: white;
      }
      
      .listItem2 h2 {
        text-align: center;
        padding: 10px 25px;
        text-align: left;
        font-size: 16px;
        line-height: 23px;
      }
      
      .listItem2 h2:hover {
        color: #426e4b;
      }
      
      .listItem2 p {
        text-align: center;
        padding: 10px 25px;
        text-align: left;
      }
      
      .listItem2:hover {
        box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
       
      }

      .imgThumbnail {
        width: 100%;
        border-radius: 8px 8px 0px 0px;
        object-fit: cover;
        height: 20vh;
      }
      .imgThumbnail:hover {
        filter: grayscale(100%);
      }

      .specieInfo {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      

      .green {
        width: 40px;
        height: 40px;
        background: #3E6947;
        padding: 8px;
        border-radius: 50%;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
       
       
      }

      .btnContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px solid rgb(230, 230, 230);
          padding: 5px;
      }

      .submitBtn {
          padding: 8px;
          border: solid #3e6947 1px;
          border-radius: 50px;
          background-color: white;
          color: black;
          cursor: pointer;
          font-family: "DM Sans", sans-serif;
      }

      .submitBtn:hover {
          background-color: #3e6947;
          color: white;
      }
      /* ###################################### */
      
  
    
      @media all and (max-width: 768px) {
      
        .items {
          width: 100%;
        }
    
        .intro {
          width: 90%;
        }
      
        .grid {
          --auto-grid-min-size: 20rem;
          width: 100%;
        }
  
       
       
      }