.featuresContainer {
    margin: 0px auto;
    width: 90%;
    padding-top: 130px;
   
   
  }
  
  /*
      AUTO GRID
      Set the minimum item size with `--auto-grid-min-size` and you'll
      get a fully responsive grid with no media queries.
    */
  .autoGrid {
    --auto-grid-min-size: 16rem;
  
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
    grid-gap: 1rem;
  }
  
  .items {
    width: 100%;
  }
  
  .paragraph {
    width: 60%;
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 50px;
  }
  
  .listItem {
    padding: 0rem 0rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem;
    list-style-type: none;
    text-align: left !important;
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
    border-radius: 8px;
  }
  
  .listItem h2 {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
  
  .listItem h2:hover {
    color: #50835a;
  }
  
  .listItem p {
    text-align: center;
    padding: 10px 25px;
    text-align: left;
  }
  
  .listItem:hover {
    box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
   
  }
  
 
  
  
  
  .imgThumbnail {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    height: 30vh;
  }
  .imgThumbnail:hover {
    filter: grayscale(100%);
    cursor: pointer;
  }
  
  .authorAndDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
  }
  
  .authorAndDate h3 {
    color: #0d9b7f;
    font-size: 10px;
  }

  .author {
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0px;
    
}


.pageNum {
  margin: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;
}
.bg {
    background-color: #3E6947;
}

.pageNum:focus {
    background-color: #3E6947;
    color: white;
}

  
  @media all and (max-width: 768px) {
    .featuresContainer {
      width: 90%;
      display: block;
    }
  
    .items {
      width: 100%;
    }
  
    .paragraph {
      width: 100%;
     
    }
  
    .imgThumbnail {
      height: 50vh;
    }
  }
  