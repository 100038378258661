
.cardLayout {
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
    text-align: center;
  
}
.cardLayout p {
    line-height: 45px;
    text-align: center;
}
.userCard {
   position: fixed;
    width: 350px;
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px;
    margin-top:68px;
    border-radius: 10px;
    background-color: white;
    z-index: 1000;
    text-align: center;
    
}

.userMenu {
    padding: 50px;
}

.avater {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}

.profileButton {
    padding: 15px 25px;
    border: solid gray 1px;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 10px;
}

.line {
    border-top: 1px solid rgb(228, 228, 228);
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

