/* .content {
  display: flex;
  justify-content: flex-start;
  padding-right: 8px;
  text-align: center;
  background-color: white;
  z-index: 2000;
} */

.content img {
  position: fixed;
  top: 0;
  width: 50px;
  height: 50px;
  background-color: white;
  /* box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgb(170, 170, 170);
  border-right: 1px solid rgb(170, 170, 170);
  border-bottom: 1px solid rgb(170, 170, 170);
  border-radius: 0px 0px 2px 2px; */
  /* padding: 3px; */
}

/* .overlay h1 {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 1px;
  font-weight: 700px;
  margin: 0 0 5px;
  color: white;
  text-align: left;
  width: 70%;
}

.overlay p {
  margin: 0;
    color: white;
    text-align: left;
} */
