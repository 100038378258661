.container {
    display: block;
    position: relative;
    padding: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .container p {
    padding-left: 25px;
    text-align: left;
    font-size: 14px;
    color: gray;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin-top: 10px;
    background-color: #008d3f;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
   /* Create a custom radio button */
   .checkmark2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin-top: 10px;
    background-color: #ffaf01;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #00642d;
  }
 
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark2 {
        background-color: #be8200;
      }
     
      
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkmark2:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark2:after {
        display: block;
      }
      
  

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    left: 8px;
    top: 0px;
    width: 6px;
    height: 16px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

    /* Style the indicator (dot/circle) */
    .container .checkmark2:after {
        left: 8px;
        top: 0px;
        width: 6px;
        height: 16px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
  